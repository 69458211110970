import React from 'react';
import '../styles.scss';
import {configData} from "../config.js";
import {fetchSigned, isRecentMessage} from "../shared/Utilities.js";
import CopyToClipboardButton from "../components/general/CopyToClipboardButton.js";
import DelayedTooltip from "../components/general/DelayedTooltip.js";

import {
  OwcTabs, OwcTab, OwcTypography, OwcTable, OwcTableHeader, OwcIcon,
  OwcTableCell, OwcTableHeaderCell, OwcTableRow, OwcTableBody,
  OwcButton, OwcInput, OwcIconButton, OwcCheckbox, OwcProgressSpinner
} from '@one/react';

/**
 * The Control for mapping system types
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */
class SystemTypes extends React.Component {
  UNSAVED_CHANGES_MESSAGE = "Unsaved changes, click Save Changes to save";
  MAPPED_TO_ALL_VALUE = "All";
  MAPPED_TO_MAPPED_VALUE = "Mapped";
  MAPPED_TO_BLANK_VALUE = "Not mapped";
  MAPPED_TO_EXCLUDED_VALUE = "Excluded";
  MAPPED_TO_DEPRECATED_VALUE = "Deprecated";
  CHANGED_VALUE = "Unsaved Changes";

  TAB_SUMMARY = "Reference Type Summary";
  TAB_CONNECTED_DEVICE = "Connected Device Mapping";
  TAB_INSTALLED_BASE = "Installed Base Mapping";
  TAB_LOADING_PLACEHOLDER = "Loading Data Sources ...";
  TAB_SPECIFIC_TEXT = {
    "Connected Device Mapping": {
      typeTooltip: "The connected device system type from the instrument data.",
      sourceCountTooltip: "The count of unique serial numbers for this connected device system type.",
      mappingDuplicatesTooltip: "Reference system type + serial number duplicates, due to multiple system types mapped to the same reference system type (updates after saving changes).",
      mappedToTooltip: "The reference system type that this is mapped to, changes to the reference type mapping are disabled if there are any mappings to agreements.",
      agreementMappingTooltip: "Mappings to agreements for this reference system type, changes to the reference type mapping are disabled if there are any mappings to agreements.",
      SearchInputTooltip: "Search by System Type",
    },
    "Installed Base Mapping": {
      typeTooltip: "The material number and name before mapping to a reference system type.",
      sourceCountTooltip: "The count of unique serial numbers for this material number. Any duplicates (listed in brackets) are due to multiple company codes maintaining details for a single device.",
      mappingDuplicatesTooltip: "Reference system type + serial number duplicates, due to multiple material numbers mapped to the same reference system type and/or multiple company codes maintaining details for a single device (updates after saving changes).",
      mappedToTooltip: "The reference system type that this is mapped to.",
      agreementMappingTooltip: "",
      SearchInputTooltip: "Search by Material name or number",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSummaryLoading: false,
      submissionState: null,
      selectedDataSource: this.TAB_SUMMARY,
      displayedTabs: [this.TAB_SUMMARY, this.TAB_LOADING_PLACEHOLDER],
      showDeprecated: false,
      summaryData: [],
      dataSources: [],
      structuredData: {},
      referenceTypes: [],
      refListsComplete:[],
      searchTerm: "",
      searchFilter: this.MAPPED_TO_ALL_VALUE,
    }

    if (isRecentMessage(props.systemTypeFilterSettings) &&
        props.systemTypeFilterSettings.startsWith(this.MAPPED_TO_BLANK_VALUE)) {
      this.state.searchFilter = this.MAPPED_TO_BLANK_VALUE;
    }
    if (isRecentMessage(props.systemTypeTabSelected) &&
        props.systemTypeTabSelected.includes(this.TAB_CONNECTED_DEVICE)) {
      this.state.selectedDataSource = this.TAB_CONNECTED_DEVICE;
    }   
    if (isRecentMessage(props.systemTypeTabSelected) &&
        props.systemTypeTabSelected.includes(this.TAB_INSTALLED_BASE)) {
      this.state.selectedDataSource = this.TAB_INSTALLED_BASE;
    }      
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.systemTypeFilterSettings.length > 0) {
      if (this.props.systemTypeFilterSettings !== prevProps.systemTypeFilterSettings) {
        if (this.props.systemTypeFilterSettings.startsWith(this.MAPPED_TO_BLANK_VALUE)) {
              this.setState({searchFilter: this.MAPPED_TO_BLANK_VALUE,
                            searchTerm: ""});
        } else {
          this.setState({searchFilter: this.MAPPED_TO_ALL_VALUE});
        }
      }
    }
    if (this.props.systemTypeTabSelected.length > 0) {
      if (this.props.systemTypeTabSelected !== prevProps.systemTypeTabSelected) {
        if (this.props.systemTypeTabSelected.includes(this.TAB_CONNECTED_DEVICE)) {
          this.setState({
            selectedDataSource:this.TAB_CONNECTED_DEVICE,
            searchTerm: ""
          });
        }   
        if (this.props.systemTypeTabSelected.includes(this.TAB_INSTALLED_BASE)) {
          this.setState({selectedDataSource:this.TAB_INSTALLED_BASE});
        }  
      }
    }
  }

  /**
     * Runs one after construction after everything is initialised
     */
  componentDidMount() {
    // load the system types
    // load the reference data
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const systemTypesList = result.filter(value =>
            value.type === "Reference System Type" && value.isActive === true
          );
          this.setState({ refListsComplete: result,
                          referenceTypes: systemTypesList});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

      this.loadMappingData();
      this.loadSummaryData();
  }

  /***
   * Loads the mapping data for all of the datasources from the API
   */
  loadMappingData() {
    this.setState ({isLoading: true});
    fetchSigned(configData.SYSTEM_TYPES_API_URL)
    .then(res => res.json())
    .then(
      (result) => {
        let dataSources = Object.keys(result);
        let selectedSource = this.state.selectedDataSource;
        // remove the agreementMappings data element
        dataSources = dataSources.filter(e => e !== 'agreementMappings');
        if ((selectedSource === null || selectedSource === this.TAB_LOADING_PLACEHOLDER) 
            && (dataSources.length > 0) ) {
          selectedSource = dataSources[0];
        }

        let newSubmissionState = null;
        if (this.state.submissionState === "Changes Successfully Saved") {
          newSubmissionState = this.state.submissionState;
        } 
        const newState = { structuredData:result,
          dataSources:dataSources,
          displayedTabs: [this.TAB_SUMMARY, ...dataSources],
          selectedDataSource: selectedSource,
          submissionState: newSubmissionState,
          isLoading: false
        };
        if (this.state.searchFilter === this.CHANGED_VALUE) {
          newState.searchFilter = this.MAPPED_TO_ALL_VALUE;
        };
        this.setState(newState);
        this.props.onUnsavedChangesChange(false);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error: error,
          isLoading: false
        });
      }
    )
  }


  /***
   * Loads the mapping data for all of the datasources from the API
   */
  loadSummaryData() {
    this.setState ({isSummaryLoading: true});
    fetchSigned(configData.SYSTEM_TYPES_API_URL + "match_counts/")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          summaryData:result,
          isSummaryLoading: false
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error: error,
          isSummaryLoading: false
        });
      }
    )
  }


  /**
   * Handles changes in the tabs for the different data sources
   * @param {*} tabName the name of the selected tab
   */
  handleTabChange(tabName) {
    if (tabName !== this.state.selectedDataSource)
    {
      this.setState({
        selectedDataSource: tabName,
        searchFilter: this.MAPPED_TO_ALL_VALUE,
        searchTerm: ""
      });
    }
  }

  /**
   * handles changes to the mapped to select boxes
   * @param {*} value the selcted text value in the text box
   * @param {*} selectedType the text of the system type in this row
   */
  handleSelectChange(value, selectedType) {
    // find the id for this code
    let selectedRefListId = null;
    let excluded = false;

    if (value !== this.MAPPED_TO_BLANK_VALUE) {
      if (value === this.MAPPED_TO_EXCLUDED_VALUE) {
        excluded = true;
      } else {
        const refList = this.state.referenceTypes;
        const reflistItem = refList.find(({ description }) => description === value);
        if (reflistItem !== undefined) {
          selectedRefListId = reflistItem.refListId;
        }
      }
    }

    // store the new value
    const newData = {...this.state.structuredData};
    const newDataList = newData[this.state.selectedDataSource]["data"];
    const entry = newDataList.find(({ type }) => type === selectedType);
    //get the index
    if (entry !== undefined) {
      if (this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE){
        if (entry.mappedTo !== null && selectedRefListId !== entry.mappedTo && entry.changed !== true) {
          // this is the removal of mapping from a mapped device type and will cause the deletion of history
          entry.previousMappedTo = entry.mappedTo;
        }
      }
      entry.mappedTo = selectedRefListId;
      entry.excluded = excluded;
      entry.changed = true;
    }
    this.setState({ entitlements: newData,
                    submissionState: this.UNSAVED_CHANGES_MESSAGE,  });
    this.props.onUnsavedChangesChange(true);
  }
  
  /**
   * Reloads the data from the database and resets the form
   */
  handleCancelClick() {
     this.loadMappingData();
  }

  /**
   * Handles submitting changes to the database
   */
  handleSubmitClick() {  
    this.setState({ submissionState: "Saving ..." });

    // start with a deep copy of all of the data
    const submissionData = JSON.parse(JSON.stringify(this.state.structuredData));
    //then remove the agreementMappings
    delete submissionData.agreementMappings;
    
    // for each data source
    for (const [dataSource, entry] of Object.entries(submissionData)) {
      const fullDataList = entry["data"];
      // filter the data down to just the changed records
      const filteredlist = fullDataList.filter(value =>
        value.changed === true
      );
      if (filteredlist) {
        submissionData[dataSource]["data"] = filteredlist;
      } else {
        submissionData[dataSource]["data"] = [];
      };
    };


    const submitForm = () => {
      // decide if it is an update or insert and setup appropriately
      return fetchSigned(configData.SYSTEM_TYPES_API_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      })
        .then((response) => {
          console.log(response.status);
          if (response.status === 201) // inserted successfully
          {
            response.json().then((json) => {  this.setState({  inputValue: "",
                                              submissionState: "Changes Successfully Saved",
                                            }, this.loadMappingData());
              this.props.onUnsavedChangesChange(false);
              this.props.onUpdateNotifications();
              console.log("API Response" + JSON.stringify(json));
            }).catch((error) => {
              this.setState({ submissionState: "Error saving changes " + error });
              console.error(error);
            });
          } else {
            response.json().then((json) => {
              console.log("API Response" + JSON.stringify(json));
              this.setState({ submissionState: "Error saving changes " + json.errorText });
            }).catch((error) => {
              this.setState({ submissionState: "Error saving changes " + error })
              console.error(error);
            });
          }
        });
    };
    submitForm();
  }

  /**
   * looks up a ref data item and returns the string with an optional deprecation warning
   * @param {*} refListIdToLookup The id to look up
   * @param {*} alternateValue if not null this will be used for the ref data value
   * @param {*} includeDeprecationWarning if true and the ref list item is not active add a warning
   * @returns the strinbg desctrption of the ref data item, or null if it cannot be found
   */
  lookupRefData(refListIdToLookup, alternateValue = null, includeDeprecationWarning = true) {
    let retVal = alternateValue;
    const refDataEntry = this.state.refListsComplete.find(({ refListId }) => refListId === refListIdToLookup);
    if (refDataEntry !== undefined) {
      if (retVal === null ) {
        retVal = refDataEntry.description;
      }
      if ((includeDeprecationWarning) && (!refDataEntry.isActive)) {
        retVal += configData.REFDATA_DEPRECATED;
      }
    }
    return (retVal);
  }

  /**
   * renders the list for the selected datasource
   * @returns the jsx of the controls
   */
  renderSelectedList() {
    let messageColour = "black";
    if (this.state.submissionState !== null && this.state.submissionState.startsWith("Err")) {
      messageColour = "red";
    }

    if (this.state.selectedDataSource===this.TAB_SUMMARY) {
      return ( 
        <div style={{ marginLeft:"0.5em", marginRight:"0.1em", width:"98%"}}>
          {this.renderSummaryTable()}
        </div>
      );
    } else if (this.state.selectedDataSource===this.TAB_LOADING_PLACEHOLDER) {
      return ( 
        <div style={{ marginLeft:"0.5em", marginRight:"0.1em", width:"98%"}}>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft:"0.5em", marginRight:"0.1em", width:"98%", display:"flex", flexDirection:"column"}}>
            {this.renderTable()}
            <br />

            <table width="100%">
            <tbody>
              <tr>
                <td align="left">
                  <OwcButton elevated style={{ width: "fit-content" }}
                    onclick={() => this.handleCancelClick()} >
                    Clear Unsaved Changes
                  </OwcButton>
                </td>
                <td align="right">
                  <OwcButton style={{ width: "fit-content" }}
                    onclick={() => this.handleSubmitClick()}
                    disabled={((this.state.submissionState === "Saving ...") ||
                                (this.state.submissionState === "Changes Successfully Saved") ||
                                (this.state.submissionState === null)) 
                                ? true : false} >
                    {this.state.submissionState === "Saving ..." ? this.state.submissionState : "Save Changes"}
                  </OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
          <OwcTypography variant="title6" style={{ marginBottom: 8, textAlign: "right", color: messageColour }}>
            {this.state.submissionState === "Saving ..." ? "" : this.state.submissionState}
          </OwcTypography>
        </div>
      );
    }
  }

  /**
   * Renders the summary table for the reference system types
   * @returns The JSX of the controls
   */
  renderSummaryTable(){
    return (
      <OwcTable key={"refTypeTableSummary"} style={{ display: "block", minWidth:"50%", maxWidth:"100%" }} size='default' height="auto">
        <OwcTableHeader elevated sticky shrink>
          <OwcTableHeaderCell width="20%" resizable>Reference System Type</OwcTableHeaderCell>
          <OwcTableHeaderCell width="16%" resizable id="tooltip-device-count">Device Count</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-device-count" placement="bottom">The number of unique connected devices mapped to this reference system type.</DelayedTooltip>
          <OwcTableHeaderCell width="16%" resizable id="tooltip-ib-count">Installed Base Count</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-ib-count" placement="bottom">The number of unique (material_no and serial_no) installed base devices mapped to this reference system type.</DelayedTooltip>
          <OwcTableHeaderCell width="16%" resizable id="tooltip-not-matched">Not matched</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-not-matched" placement="bottom">The count of connected devices that had no matching installed base device and therefore are not enriched.</DelayedTooltip>
          <OwcTableHeaderCell width="16%" resizable id="tooltip-matched">Matched</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-matched" placement="bottom">The count of connected devices that are matched to an installed base device.</DelayedTooltip>
          <OwcTableHeaderCell width="16%" resizable id="tooltip-multiple-matches">Multiple matches</OwcTableHeaderCell>
          <DelayedTooltip anchor="tooltip-multiple-matches" placement="bottom">The count of connected devices that more than one matching installed base device, and therefore are not enriched.</DelayedTooltip>
        </OwcTableHeader>
        <OwcTableBody>
          {this.state.summaryData.map(listItem => this.renderSummaryTableRow(listItem))}
        </OwcTableBody>
      </OwcTable>
    );
  }

  /**
   * renders the table row for the summary data
   * @param {*} listItem the dictionary of the item to render
   * @returns the jsx of the controls
   */
  renderSummaryTableRow(listItem) {
    if (listItem.isActive || this.state.showDeprecated) {
      return (
        <OwcTableRow key={"rowSummary" + listItem.referenceSystemTypeId}>
          <OwcTableCell key={"summaryTypeCell" + listItem.referenceSystemTypeId}
            style={{wordBreak:"break-word"}} valign="middle"
          >
             {listItem.referenceSystemType}
             {listItem.isActive===false?configData.REFDATA_DEPRECATED:""}
          </OwcTableCell>
          <OwcTableCell key={"summaryDeviceCountCell" + listItem.referenceSystemTypeId} valign="middle">
            {listItem.deviceCount===0?
              <OwcTypography style={{color:"orange"}}>Not Mapped</OwcTypography>
            :
              listItem.deviceCount
            }
          </OwcTableCell>
          <OwcTableCell key={"summaryIBCountCell" + listItem.referenceSystemTypeId} valign="middle">
            {listItem.ibDeviceCount===null?
              <OwcTypography style={{color:"orange"}}>Not Mapped</OwcTypography>
            :
              listItem.ibDeviceCount
            }
          </OwcTableCell>
          <OwcTableCell key={"summaryNotMatchedCell" + listItem.referenceSystemTypeId} valign="middle">
            {listItem.notMatched}
          </OwcTableCell>
          <OwcTableCell key={"summaryMatchedCell" + listItem.referenceSystemTypeId} valign="middle">
            {listItem.matched}
          </OwcTableCell>
          <OwcTableCell key={"summaryMultipleMatchesCell" + listItem.referenceSystemTypeId} valign="middle">
            {listItem.multipleMatches}
          </OwcTableCell>
        </OwcTableRow>
      );
    }
  }


  /**
   * Renders the datasource mapping table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const selectedSource = this.state.structuredData[this.state.selectedDataSource];
    let selectedList = []
    if (selectedSource !== undefined) {
      selectedList = selectedSource["data"];
      return (
        <OwcTable key={"refListTable" + this.state.selectedDataSource} style={{ display: "block", minWidth:"50%", maxWidth:"100%" }} size='default' height="auto">
          <OwcTableHeader elevated sticky shrink>
          <OwcTableHeaderCell width={this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE?"20%":"30%"} resizable id="tooltip-type">{selectedSource["typeColName"]}</OwcTableHeaderCell>
            <DelayedTooltip anchor="tooltip-type" placement="bottom">{this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].typeTooltip}</DelayedTooltip>
            <OwcTableHeaderCell width="20%" resizable id="tooltip-used-by">Source Unique Serial Numbers</OwcTableHeaderCell>
            <DelayedTooltip anchor="tooltip-used-by" placement="bottom">{this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].sourceCountTooltip}</DelayedTooltip>
            <OwcTableHeaderCell width={this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE?"20%":"30%"} resizable id="tooltip-mapped-to">Mapped to Reference System Type</OwcTableHeaderCell>
            <DelayedTooltip anchor="tooltip-mapped-to" placement="bottom">{this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].mappedToTooltip}</DelayedTooltip>
            <OwcTableHeaderCell width="20%" resizable id="tooltip-mapping-duplicates">Duplicates after mapping</OwcTableHeaderCell>
            <DelayedTooltip anchor="tooltip-mapping-duplicates" placement="bottom">{this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].mappingDuplicatesTooltip}</DelayedTooltip>
            {this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE?
              <>
                <OwcTableHeaderCell width="20%" resizable id="tooltip-mapping-device_coverage">Mappings to agreements</OwcTableHeaderCell>
                <DelayedTooltip id="tooltip-mapping-device_coverage_tip" anchor="tooltip-mapping-device_coverage" placement="bottom">
                  {this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].agreementMappingTooltip}
                </DelayedTooltip>
              </>
            :
            ""
            }

          </OwcTableHeader>
          <OwcTableBody>
            {selectedList.map((listItem, index) => this.renderTableRow(listItem, index, selectedSource["typeColName"]))}
          </OwcTableBody>
        </OwcTable>
      );
    } else {
      return (
        <OwcProgressSpinner></OwcProgressSpinner>
      );
    }
  }

  /**
   * renders the table row for the provided data
   * @param {*} listItem the dictionary of the item to render it needs the following keys defined (type, mappedTo, count)
   * @param {*} index the ordinal index of the row
   * @returns the jsx of the controls
   */
  renderTableRow(listItem, index, typeColName) {
    let shouldDisplay = true;
    //only render the row if it passes the filter and search criteria
    if (this.state.searchTerm !== undefined && this.state.searchTerm) {
      // seach based on type normally, but also material name for installed base
      const searchedData = listItem.type.toUpperCase();
      if (this.state.selectedDataSource === this.TAB_INSTALLED_BASE)
      {
        const searchedMaterialName = listItem.name.toUpperCase();
        if ((Number(searchedData) !== Number(this.state.searchTerm)) &&
            (searchedMaterialName.indexOf(this.state.searchTerm.toUpperCase()) === -1))
        {
          shouldDisplay = false;
        }
      }
      else {
        if (searchedData.indexOf(this.state.searchTerm.toUpperCase()) === -1)
        {
          shouldDisplay = false;
        }
      }
    }

    const searchFilter = this.state.searchFilter;
    // to be mapped
    if (searchFilter === this.MAPPED_TO_BLANK_VALUE)
    {
      if (listItem.mappedTo !== null || listItem.excluded === true) {
        shouldDisplay = false;
      }
    }    
    // excluded
    if (searchFilter === this.MAPPED_TO_EXCLUDED_VALUE)
    {
      if (listItem.excluded === false) {
        shouldDisplay = false;
      }
    }
    // mapped
    if (searchFilter === this.MAPPED_TO_MAPPED_VALUE)
    {
      if (listItem.mappedTo === null) {
        shouldDisplay = false;
      }
    }
    // deprecated
    if (searchFilter === this.MAPPED_TO_DEPRECATED_VALUE)
    {
      let isDeprecated = false;
      if (listItem.mappedTo !== null && listItem.mappedTo !== undefined) {
          const mappedToValue = this.lookupRefData(listItem.mappedTo);
          if ((mappedToValue !== null) &&
              (mappedToValue.endsWith(configData.REFDATA_DEPRECATED))
             ) {
              isDeprecated = true;
          }
      }

      if (isDeprecated === false) {
        shouldDisplay = false;
      }
    }
    // changed
    if (searchFilter === this.CHANGED_VALUE)
    {
      if (listItem.changed !== true) {
        shouldDisplay = false;
      }
    }

    if (shouldDisplay === true) {
      const refListId = listItem.mappedTo;
      const duplicateDict = this.state.structuredData[this.state.selectedDataSource]["duplicates"];
      const sourceDuplicateDict = this.state.structuredData[this.state.selectedDataSource]["sourceDuplicates"];
      let countText = listItem.count;
      if (sourceDuplicateDict !== undefined && listItem.type in sourceDuplicateDict) {
        countText = this.getCountText(listItem, sourceDuplicateDict[listItem.type], typeColName);
      }
      let mappedduplicateText = "0";
      if (duplicateDict !== undefined && refListId in duplicateDict) {
        mappedduplicateText = this.mappedDuplicateText(listItem, duplicateDict[refListId]);
      }

      
      const agreementMappingList = this.state.structuredData["agreementMappings"];
      let agreementMappingDeviceCount = 0;
      let agreementMappingCount = 0;
      const agreementMappingItem = agreementMappingList.find(({ refListId }) => refListId === listItem.mappedTo);
      if (agreementMappingItem !== undefined) {
        agreementMappingDeviceCount = agreementMappingItem.serialNoCount;
        agreementMappingCount = agreementMappingItem.mappingCount;
      }
      // if there are mappings and this is connected device then disable the mapping select box
      const isDisabled = (this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE &&                 
                          (agreementMappingDeviceCount>0 || agreementMappingCount>0) &&
                          listItem.changed !== true
                          );
      
      return (
        <OwcTableRow key={"row" + listItem.type}>
          <OwcTableCell key={"descriptionCell" + listItem.type}
              style={{wordBreak:"break-word"}} valign="middle">
            <div style={{display: "flex", flexDirection:"row", alignItems: 'center' }}>
              {
                this.state.selectedDataSource === this.TAB_INSTALLED_BASE
                ?(
                  <div>
                    <OwcTypography>{listItem.name}</OwcTypography>
                    <br/>
                    <OwcTypography variant="caption">{parseInt(listItem.type)}</OwcTypography>
                  </div>
                )
              :
              listItem.type
              }
              {listItem.changed === true?
                (
                  <div>
                    <OwcIcon id={"mappingChangedIcon" + listItem.type}
                      key={"mappingChangedIcon" + listItem.type}
                      name="save" style={{ display: "inline-block", verticalAlign: "top", fontSize:"1.75em"}} />
                    <DelayedTooltip key={"mappingChangedIconToolTip" + listItem.type}
                        anchor={"mappingChangedIcon" + listItem.type} 
                        placement="left">
                      This mapping has been changed and will be stored when you click Save Changes.
                    </DelayedTooltip>
                  </div>
                )
              :
                ""
              }
            </div>
          </OwcTableCell>          
          <OwcTableCell key={"countCell" + listItem.type} valign="middle">
            {countText}
          </OwcTableCell>
          <OwcTableCell key={"mappingCell" + listItem.type}
            id={"mappingCell" + listItem.type}
            style={{wordBreak:"break-word"}} valign="middle">
              <div style={{display:"flex", flexDirection:"column"}}>
            {this.renderMappingSelectList(listItem.type, listItem.mappedTo, listItem.excluded, isDisabled)}
            {listItem.previousMappedTo !== undefined && listItem.previousMappedTo !== listItem.mappedTo?
              <OwcTypography style={{color:"orange"}} variant="body">All device history for these devices will be deleted when saved.</OwcTypography>
            :
              ""
            }
            </div>
          </OwcTableCell>
          {isDisabled?
            <DelayedTooltip 
              key={"mappedCellTooltip" + listItem.type}             
              anchor={"mappingCell" + listItem.type} >
              This reference system type has mappings to agreements, and cannot be changed until those mappings are removed.
            </DelayedTooltip>
            :
            ""
          }
          <OwcTableCell key={"mappingDuplicateCell" + listItem.type} valign="middle">
            {mappedduplicateText}
          </OwcTableCell>

          {this.state.selectedDataSource === this.TAB_CONNECTED_DEVICE?
            <OwcTableCell key={"mappingDeviceCoverageCell" + listItem.type} 
                          valign="middle">
              <OwcTypography>
                {agreementMappingDeviceCount} devices<br/>
                {agreementMappingCount} mappings
              </OwcTypography>
            </OwcTableCell>         
          :
            ""
          }

        </OwcTableRow>
      );
    }
  }

  /**
   * creates the formatted count text when there are duplicates at the source
   * @param {*} item 
   * @param {*} duplicates
   * @param {*} typeColName
   */
  getCountText(item, duplicates, typeColName) {
    return (
      <>
      <OwcTypography style={{color: "orange"}} key={"countCellText" + item.type} id={"countCellText" + item.type}>
        {item.count} ({duplicates.count} duplicate{duplicates.count===1?"":"s"})
      <DelayedTooltip key={"countCellTextTooltip" + item.type} anchor={"countCellText" + item.type}>
        {duplicates.count} serial number{duplicates.count===1?"":"s"} for the {typeColName.toLowerCase()} "{item.type}"
        are repeated in the source data. 
        ({(duplicates.count < 3)?duplicates.serialNoList.join(", "):duplicates.serialNoList.slice(0,3).join(", ") + ", ..."})
      </DelayedTooltip>
      </OwcTypography>
      <CopyToClipboardButton key={"countCellCopyBtn" + item.type} id={"countCellCopyBtn" + item.type}
        text={duplicates.serialNoList.join("\n")}
        iconTooltip="Copy duplicate serial numbers to clipboard"
      />
      </>
      );
  }

 
  /**
   * creates the formatted count text when there are duplicates after mapping
   * @param {*} item 
   * @param {*} duplicates 
   */
  mappedDuplicateText(item, duplicates) {
    return (
      <>
      <OwcTypography style={{color: "orange"}} key={"mappedDuplicateCellText" + item.type} id={"mappedDuplicateCellText" + item.type}>
        {duplicates.count} 
      <DelayedTooltip key={"mappedDuplicateCellTextTooltip" + item.type} anchor={"mappedDuplicateCellText" + item.type}>
        {duplicates.count} serial number{duplicates.count===1?"":"s"} for the reference type "{this.lookupRefData(item.mappedTo)}"
        are repeated, these have been excluded from the list of devices.
        ({(duplicates.count < 3)?duplicates.serialNoList.join(", "):duplicates.serialNoList.slice(0,3).join(", ") + ", ..."})
      </DelayedTooltip>
      </OwcTypography>
      <CopyToClipboardButton key={"mappedDuplicateCellCopyBtn" + item.type} id={"mappedDuplicateCellCopyBtn" + item.type}
        text={duplicates.serialNoList.join("\n")}
        iconTooltip="Copy duplicate serial numbers to clipboard"
      />
      </>
      );
  }

  /**
   * Renders a select control for selecting the mapped reference system type
   * @param {*} type the current type being mapped
   * @param {*} mappedTo the current ref_list_id it is mapped to
   * @param {*} excluded if true the item is excluded
   * @param {*} isDisabled if true the item is disabled from changes to the mapping
   * @returns the jsx of the control
   */
  renderMappingSelectList(type, mappedTo, excluded, isDisabled) {
    let mappedToValue = this.MAPPED_TO_BLANK_VALUE;
    if ((mappedTo !== null)  && (mappedTo !== undefined)) {
      mappedToValue = this.lookupRefData(mappedTo);
    }
    if (excluded === true) {
      mappedToValue = this.MAPPED_TO_EXCLUDED_VALUE;
    }
    return(
        <select style={(mappedToValue === null || 
          mappedToValue === this.MAPPED_TO_EXCLUDED_VALUE)?
            { width: "100%", color:"red" }:
            (mappedToValue === this.MAPPED_TO_BLANK_VALUE)?
            { width: "100%", color:"orange" }:
            { width: "100%"}}
          id={"mappedTo" + type}
          key={"mappedTo" + type}
          value={mappedToValue}
          disabled={isDisabled}
          onChange={(ev) => this.handleSelectChange(ev.target.value, type)}>
          <option key={"mappedTo" + type + "Null"} style={{color:"orange"}}>
            {this.MAPPED_TO_BLANK_VALUE}</option>
          <option key={"mappedTo" + type + "Excluded"} style={{color:"red"}}>
            {this.MAPPED_TO_EXCLUDED_VALUE}</option>
          { // if the item point to a deprecated value
          mappedTo !== null && mappedToValue !== undefined && mappedToValue !== null && 
          mappedToValue.endsWith(configData.REFDATA_DEPRECATED)
          ? <option key={"mappedTo" + mappedToValue} style={{color:"black"}}>
          {mappedToValue}</option>
          : ""
          }
          {this.state.referenceTypes.map((item, index) => (
          <option
          key={"mappedTo" + type + index} style={{color:"black"}}>
          {item.description}
          </option>
          ))}
        </select>
    );
  }

  /**
   * Renders the selection controls
   * @returns The JSX of the controls
   */
  renderSelectionControls(){
    if (this.state.selectedDataSource===this.TAB_SUMMARY) {
      return(
        <OwcCheckbox key="referenceTypeDeprecatedBox"
            checked={this.state.showDeprecated} 
            onValueChange={(ev)=> this.setState({showDeprecated: ev.detail})}>
          <OwcTypography>Show Deprecated Types</OwcTypography>
        </OwcCheckbox>
      );
    } else {
      return(
        <>
          <OwcTypography style={{marginRight:"0.5em"}}>Show</OwcTypography>
          <select 
            key={"FilterByStatusSelect"}
            value={this.state.searchFilter}
            onChange={(ev) => {this.setState({searchFilter:ev.target.value})}}>
            <option>{this.MAPPED_TO_ALL_VALUE}</option>
            <option>{this.MAPPED_TO_EXCLUDED_VALUE}</option>
            <option>{this.MAPPED_TO_BLANK_VALUE}</option>
            <option>{this.MAPPED_TO_MAPPED_VALUE}</option>
            <option>{this.MAPPED_TO_DEPRECATED_VALUE}</option>
            <option>{this.CHANGED_VALUE}</option>
          </select>
          <OwcInput
              style={{ width: "15em",  marginLeft:"0.5em"}}
              id="SearchInput"
              placeholder="Search..."
              round={false}
              variant="filled"
              value={this.state.searchTerm}
              onValueChange={(ev) => {this.setState({searchTerm:ev.detail})}}>
            <OwcIconButton slot="prefix" icon="search" />
            <DelayedTooltip key={"SearchInputTooltip"} anchor="SearchInput" placement="bottom">
              { 
                this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource]
                  ? this.TAB_SPECIFIC_TEXT[this.state.selectedDataSource].SearchInputTooltip
                  : ""
              }
            </DelayedTooltip>
          </OwcInput>  
        </>
      );
    }
  }

  /**
   * Renders the tabss
   * @returns The JSX of the controls
   */
  renderTabs(){
    return(
      <OwcTabs value={this.state.selectedDataSource} onValueChange={(ev) => this.handleTabChange(ev.detail)}>
        {
          this.state.displayedTabs.map(name => (
            <OwcTab key={"Tab" + name.replace(/\s/g, "")} value={name}>
              <OwcTypography>{name}</OwcTypography>
            </OwcTab>))
        }
      </OwcTabs>
    );
  }

  /**
   * Renders this form
   * @returns the jsx of the form
   */
  render() {
    if (this.state.isSummaryLoading) {
      return (
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
          <OwcProgressSpinner style={{marginTop:'30px'}}/>
        </div>
      );
    }

    return (
      <>
        <div style={{display:"flex", flexDirection:"row", flexWrap:"nowrap",
            alignItems:"center", JustifyContent:"space-between",
            alignContent:"space-between", width:"99.5%"}}>
          {this.renderTabs()}
          <div style={{flexGrow:"3"}}></div>
          {this.renderSelectionControls()}
        </div>
        <div name="tabContents" className="tabContents">
          {this.state.selectedDataSource!==this.TAB_SUMMARY && this.state.isLoading?
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
              <OwcProgressSpinner style={{marginTop:'30px'}}/>
            </div>
          :
            this.renderSelectedList()
          }
        </div>
      </>
    )
  }
}

export default SystemTypes;
