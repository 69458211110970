import React from 'react';
import {configData} from "../../config.js";
import '../../styles.scss';

import {formatDate, fetchSigned,
  isRecentMessage, createTimedMessage, extractTimedMessage} from '../../shared/Utilities.js'
import DelayedTooltip from "../general/DelayedTooltip.js";
import {
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell,
  OwcTypography, OwcPagination,
  OwcInput, OwcIconButton, OwcButton, OwcIcon, OwcProgressSpinner,
} from '@one/react';
import TextWithLookup from '../general/TextWithLookup.js';
import DeviceDetails from './DeviceDetails.js';
import DeviceCuration from './DeviceCuration.js';

/**
 * The a device serach page control for selecting devices
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceSearch extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      systemTypeList: [],
      countryList: [],
      moveSignalList: ["All", "Move Signal"],
      mappingList: ["All", "Only Mapped", "Not Mapped"],
      excludedList: ["All", "Only Excluded", "Not Excluded"],
      enrichedList: ["All", "Not Matched", "Matched", "Multiple Matches"],
      enrichedFilter: "All",
      excludedFilter: "All",
      systemTypeFilter: "All",
      countryFilter: "All",
      moveSignalFilter: "All",
      mappingFilter: "All",
      sortList: ["Serial No",
        "System Type",
        "Data Manager Serial No",
        "Remote Service Serial No",
        "Lab Id",
        "Installation Date",
        "Last Upload Date",],
      searchList: ["Serial No",
        "Data Manager Serial No",
        "Remote Service Serial No",
        "Lab Id",
        "Device Location",
        "Account No",],
      page: 1,
      rowsPerPage: 10,
      totalPages: 1,
      displayDeviceDetails: null,
      displayLocationForDevice: null,
      searchedSerialNo: null,
    };
    this.state = this.clearFilters(this.state);
    
    if (props.deviceMoveFilterSettings.startsWith("Move Signal")) {
      this.state.moveSignalFilter = "Move Signal";
    }
  }

  /*** helper method to clear all of the filter options and return the dictionary to be applied to the state
   * @param inputDict optional a dictionary to make the changes to
   */
  clearFilters(inputDict={}){
    inputDict.searchBy = "Serial No";
    inputDict.searchTerm = "";
    inputDict.sortBy = "Serial No";
    inputDict.systemTypeFilter = "All";
    inputDict.countryFilter = "All";
    inputDict.moveSignalFilter = "All";
    inputDict.mappingFilter = "All";
    inputDict.enrichedFilter = "All";
    inputDict.excludedFilter = "All";
    return inputDict;
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    // we might need to update if we have moved to or from MapToAccounts and the filter selected was not All
    if (this.props.deviceMoveFilterSettings.length > 0) {
      if (this.props.deviceMoveFilterSettings !== prevProps.deviceMoveFilterSettings) {
        if (this.props.deviceMoveFilterSettings.startsWith("Move Signal")) {
            const newState = this.clearFilters();
            newState.moveSignalFilter = "Move Signal";
            this.setState(newState, () => this.loadSearchResults());
        } else {
          this.setState({moveSignalFilter: "All"}, () => this.loadSearchResults());
        }
      }
    }

    // we have been signalled to update
    if (this.props.updateFlag !== prevProps.updateFlag) {
      this.loadSearchResults();
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the countries list
    fetchSigned(configData.DEVICE_DETAILS_API_URL + "countries/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ countryList: result });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    // load the reference data and split out the system type list
    // load the reference data and split out the affilate list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredsystemTypeList = result.filter(value =>
            value.type === "Reference System Type"
          );
          this.setState({ systemTypeList: filteredsystemTypeList });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.loadSearchResults();
  }

  /**
   * Load the results of a search from the API into the state
   * @param {*} resetPage 
   */
  loadSearchResults(resetPage=true) {
    // if searched by serail_no store the saerch term
    let searchedSerialNo = null;
    if (this.state.searchBy === "Serial No"  && this.state.searchTerm !== null && this.state.searchTerm.length > 0){
      searchedSerialNo = this.state.searchTerm
    }

    this.setState({
      isLoaded: false,
      page: resetPage? 1: this.state.page,
      error: null,
      searchedSerialNo: searchedSerialNo
    });
    //gather searchData
    const searchData = {
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      systemTypeFilter: this.getIdForRefData(this.state.systemTypeFilter, this.state.systemTypeList),
      excludedFilter: this.state.excludedFilter,
      moveSignalFilter: this.state.moveSignalFilter,
      countryFilter: this.state.countryFilter,
      mappingFilter: this.state.mappingFilter,
      enrichedFilter: this.state.enrichedFilter,
      limit: this.state.rowsPerPage,
      offset: (this.state.page - 1) * this.state.rowsPerPage,
    };
    if (searchData.systemTypeFilter === "All") {
      searchData.systemTypeFilter = null;
    };
    if (searchData.countryFilter === "All") {
      searchData.countryFilter = null;
    };
    if (searchData.mappingFilter === "All") {
      searchData.mappingFilter = null;
    };
    if (searchData.excludedFilter === "All") {
      searchData.excludedFilter = null;
    };
    if (searchData.enrichedFilter === "All") {
      searchData.enrichedFilter = null;
    };

    console.log(JSON.stringify(searchData));

    fetchSigned(configData.DEVICE_DETAILS_API_URL + "search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          const maxPage = Math.ceil(result.totalResultCount / this.state.rowsPerPage);
          this.setState({
            isLoaded: true,            
            totalPages: maxPage,
            items: result.searchResults,
          });
          console.log(`total results: ${result.totalResultCount}, returned ${result.searchResults.length}`);
          console.log(`rows per page: ${this.state.rowsPerPage}, max page ${maxPage}`);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error: "Error searching for devices " + error,
            isLoaded: true,
            items: [],
          });
        }
      )
  }

  /**
   * Gets the id for a ref data description
   * @param {*} value the description
   * @param {*} refdataList the list to search
   * @returns the ref list id or null if not found
   */
  getIdForRefData(value, refdataList) {
    let retVal = null;
    const searchText = value.endsWith(configData.REFDATA_DEPRECATED)? 
                          value.slice(0, -configData.REFDATA_DEPRECATED.length)
                          : value;

    const refDataItem = refdataList.find(({ description }) => description === searchText);
    //get the record
    if (refDataItem !== undefined) {
      retVal = refDataItem.refListId;
    }
    return retVal;
  }

  /**
   * Handles clicks on the reset button
   */
  handleResetClick() {
    const newState = this.clearFilters();
    this.setState(newState, ()  => this.loadSearchResults());
  }

  /**
   * Handles Changes to the pagination controls for the results
   * @param {*} ev The event object
  */
  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
      stateChanges.page = 1;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults(false));
    }
  }

  /**
   * Handles changes to the system type filter
   * @param {*} ev The event object
   */
  handleSystemTypeFilterChange(ev) {
    if (ev.target.value !== this.state.systemTypeFilter) {
      this.setState({ systemTypeFilter: ev.target.value }, () => this.loadSearchResults());
    }
  }

  /**
   * Handles changes to the sort dropdown list
   * @param {*} ev The event object
   */
  handleSortChange(ev) {
    if (ev.target.value !== this.state.sortBy) {
      this.setState(
        {
          sortBy: ev.target.value,
          page: 1,
        },
        () => this.loadSearchResults());
    }
  }

  /**
   * Handles clicks on the search button
   * @param {*} ev The event object
   */
  handleSearchClick(ev) {
    this.loadSearchResults();
  }

  /**
   * Handles pressing enter in the search box to start a search
   * @param {*} ev The event object
   */
  handleSearchKeyUp(ev) {
    // Number 13 is the "Enter" key on the keyboard
    this.setState({ searchTerm: ev.target.value });
    if (ev.keyCode === 13) {
      // Cancel the default action, if needed
      ev.preventDefault();
      // Start the search
      this.loadSearchResults();
    }
  }

  /**
   * Handles requests to display the device details for the selected id
   * @param {*} ev The event object
   * @param {*} deviceId The device id selected
   */
  handleDetailsClick(ev, deviceId) {
    this.setState({displayDeviceDetails:createTimedMessage(deviceId)});
  }

  /**
   * Gets the account text to displaty for this device
   * @param {*} item The device data
   * @returns The account text to display wrapped in a span
   */
  getAccountsText(item) {
    const messageList = [];
    if (item.shipToAccountNo !== null) {
      messageList.push("shipTo: " + item.shipToAccountNo);
    }
    if (item.soldToAccountNo !== null) {
      messageList.push("soldTo: " + item.soldToAccountNo);
    }
    if (item.billToAccountNo !== null) {
      messageList.push("billTo: " + item.billToAccountNo);
    }
    if (item.instrumentLocationAccountNo !== null) {
      messageList.push("Location: " + item.instrumentLocationAccountNo);
    }
    if (messageList.length > 0){
      return(
        <span>
          {messageList.map((message,index) =>(<pre key={item.deviceId + index} style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
        </span>);
    } else {
      return(
        <span style={{overflowWrap:"normal"}}>
          None
        </span>
      );
    }
  }

  /**
   * Renders the agreements table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const error = this.state.error;
    const isLoaded = this.state.isLoaded;
    const items = this.state.items;

    if (error) {
      return <div style={{ paddingTop: "1em", paddingBottom: "23em" , color: "red"}}>Error: {error}</div>;
    } else if (this.state.items.length===0  && isLoaded === false){
      // if there is no data the table does not show the progress spinner correctly
      return (
        <div key={"AgreementNavigationProgressSpinnerDiv"} 
            style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <OwcProgressSpinner key={"AgreementNavigationProgressSpinner"}></OwcProgressSpinner>
        </div>
      );
    } else {
      return (
        <OwcTable key={"AgreementNavigationTable"} size='dense' elevated showRowExpanderColumn >
          <OwcTableHeader sticky>
              <OwcTableHeaderCell key="titleHeader" resizable width="12%">Serial No</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleCustName" resizable width="16%">Ref System Type</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleRocheName" resizable width="16%">Lab Id</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleAccountNo" resizable width="16%">Account No</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleAccountName" resizable width="16%">Account Name</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleLocation" resizable>Device Location</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody loading={!isLoaded}>
            {items.map(item => this.renderTableRow(item))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }

  /**
   * Renders a for for a device
   * @param {*} item The device data
   * @returns The JSX of the controls
   */
  renderTableRow(item){
    const keyEnding = item.deviceId;

    let tooltip = "";
    let rowColour = configData.COLOUR_WHITE;
    if (item.current !== undefined && item.current === false) {
      // the match was to values in this devices history
      tooltip = "The search matched to historic values for this device, look at the device details to view the current values.";
      rowColour = configData.COLOUR_YELLOW;
    }
    
    if (this.state.searchedSerialNo !== null && item.serialNo !== null 
      && !item.serialNo.toUpperCase().includes(this.state.searchedSerialNo.toUpperCase())) {
      // the match was to a source serial no
      tooltip = `The search for serial no '${this.state.searchedSerialNo}' matched a source serial no for this device.`;
      rowColour = configData.COLOUR_YELLOW;
    }

    if (this.state.searchedSerialNo !== null && item.serialNo !== null 
        && !item.serialNo.toUpperCase().includes(this.state.searchedSerialNo.toUpperCase())) {
      // the match was to a source serial no
      tooltip = `The search for serial no '${this.state.searchedSerialNo}' matched a source serial no for this device.`;
      rowColour = configData.COLOUR_YELLOW;
    }

    if (this.state.searchedSerialNo !== null && item.serialNo !== null 
        && !item.serialNo.toUpperCase().includes(this.state.searchedSerialNo.toUpperCase())) {
      // the match was to a source serial no
      tooltip = `The search for serial no '${this.state.searchedSerialNo}' matched a source serial no for this device.`;
      rowColour = configData.COLOUR_YELLOW;
    }

    if (item.excluded !== undefined && item.excluded === true) {
      // the device is marked as excluded
      if (item.deviceComment !== null) {
        tooltip = `This device is marked as excluded with the comment "${item.deviceComment}".`;
      } else {
        tooltip = `This device is marked as excluded.`;
      }
      rowColour = configData.COLOUR_EXCLUDED
    }

    return (
      <OwcTableRow
          key={"row" + keyEnding}
          id={"row" + keyEnding} 
          expandable 
          style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}}
          ondblclick={(ev) => this.handleDetailsClick(ev, item.deviceId)}>
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, whiteSpace:"nowrap"}: {whiteSpace:"nowrap"}} 
            key={"serialNoCell" + keyEnding} valign="middle">
          <TextWithLookup text={item.serialNo}
              id={keyEnding}
              onClick={(ev) => this.handleDetailsClick(ev, item.deviceId)}/>
            {item.current !== undefined && item.current === false
              ? <OwcIcon key={"serialNoHistoryIcon" + keyEnding} name="history"/>
              : ""
            }
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"systemTypeCell" + keyEnding} valign="middle">
          {item.referenceSystemType + (item.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}} 
            key={"labIdCell" + keyEnding} valign="middle">
          {item.labId}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"accountNoCell" + keyEnding} id={"accountNoCell" + keyEnding} valign="middle">
          <OwcTypography key={"accountNoCellText" + keyEnding}
            id={"accountNoCellText" + keyEnding}>
            {this.getAccountsText(item)}
          </OwcTypography>
        </OwcTableCell>
        <DelayedTooltip key={"accountNoCellTooltip" + keyEnding}
          anchor={"accountNoCellText" + keyEnding}
          placement="right"
          disableTriggers = {(item.installedBaseMatchCount === 1)}>
            {item.installedBaseMatchCount === 1?
              ""
            :
              (<>Device details not enriched as this mapped to {item.installedBaseMatchCount} installed base records</>)
            }
        </DelayedTooltip>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"accountNameCell" + keyEnding} valign="middle">
          {item.locationAccountName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"instrumentAddressCell" + keyEnding} valign="middle">
          {item.instrumentAddress}
        </OwcTableCell>
        <div key={"expandedRow" + keyEnding} slot="expanded"
          style={{"display": "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "flex-start", "gap": "1em"}}
        >
          <div>
            <b>Connected Device Data:</b><br />
            <b>Last Upload Date:</b> {formatDate(item.lastUploadDate)}
            {item.dataManagerSerialNo===null?"":<><br /><b>Data Manager Serial No:</b> {item.dataManagerSerialNo}</>}
            {item.remoteServiceDeviceSerialNo===null?"":<><br /><b>Remote Service Device Serial No:</b> {item.remoteServiceDeviceSerialNo}</>}
            {item.macAddress===null?"":<><br /><b>Mac Address:</b> {item.macAddress}</>}
          </div>
          <div>
            <b>Installed Base Data:</b>
            {item.installDate===null?"":<><br /><b>Install Date:</b> {formatDate(item.installDate)}</>}
            {item.materialName===null?"":<><br /><b>Material Name:</b> {item.materialName}</>}
            {item.placementType===null?"":<><br /><b>Placement Type:</b> {item.placementType}</>}
            {item.userStatus===null?"":<><br /><b>User Status:</b> {item.userStatus}</>}
          </div>
          <div>
            <b>Actions:</b><br />
            <OwcButton key={"locationSearchButton" + keyEnding} 
              onclick={(ev)=>{this.setState({displayLocationForDevice: createTimedMessage(item.deviceId)})}}>
                Curate / Show devices at this location
            </OwcButton>
          </div>
        </div>
        <DelayedTooltip key={"rowAgreementToolTip" + keyEnding}
            disableTriggers = {(tooltip === "")}
            anchor={"row" + keyEnding}>
         {tooltip}
        </DelayedTooltip>
      </OwcTableRow>
    );
  }

  /**
 * Renders the tab specific filter controls for the Map to Accounts Tab
 * @returns The JSX of the controls
 */
  renderSelectFilter(title, stateKey, optionList, widthString="100%") {
    return(
      <>
        <td> <OwcTypography>{title}</OwcTypography> </td>
        <td>
          <select style={{ width: widthString, position: "relative", zIndex:"2"}}
            value={this.state[stateKey]}
            onChange={(ev) => {
                if (ev.target.value !== this.state[stateKey]) {
                const newState = {};
                newState[stateKey] = ev.target.value;
                this.setState(newState, () => this.loadSearchResults());
              }
            }}
          >
            {optionList.map((item,index) => (
              <option key={"filter" + stateKey + index}>{item}</option>
            )
            )}
          </select>
        </td>
      </>
    );
  }
  
  /**
 * Renders the affiliate filter control
 * @returns The JSX of the controls
 */
  renderSearchAndSort() {
    const tableMinHeight = "8em";
    let formattedSystemTypeList = this.state.systemTypeList.map(item=>item.description + (item.isActive===false?configData.REFDATA_DEPRECATED:""));
    formattedSystemTypeList.unshift("All");
    let fomattedCountryList = this.state.countryList.slice();
    fomattedCountryList.unshift("All");
    return (
      <div style={{display:"flex", justifyContent:"space-between", 
                   marginTop:"0.5em", alignItems: "flex-start"}}>
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
                position: "absolute",
                top: "-0.8em",
                fontSize: "0.75em",
                backgroundColor: "white",
                border: "1px solid white", zIndex:"1"
              }}>
            Search
          </div>
          <table style={{minHeight:tableMinHeight}}>
            <tbody>
              <tr>
                <td> <OwcTypography style={{position: "relative", zIndex:"2"}}>Search by:</OwcTypography> </td>
                <td colSpan="2">
                  <select style={{ width: "100%", position: "relative", zIndex:"2" }}
                      value={this.state.searchBy}
                      onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }} >
                    {this.state.searchList.map(item => (<option key={"NavSearch" + item}>{item}</option>))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                    <OwcInput
                        style={{ width: "100%" }}
                        placeholder="Press Enter to Search..."
                        round={false}
                        variant="filled"
                        no-clear-icon
                        value={this.state.searchTerm}
                        onValueChange={(ev) => this.setState({ searchTerm: ev.detail })}
                        onKeyUp={(ev) => this.handleSearchKeyUp(ev)}>
                      <OwcIconButton slot="prefix" icon="search" onClick={(ev) => this.handleSearchClick(ev)} />
                    </OwcInput>
                </td>
                <td>
                    <OwcButton style={{marginLeft:"0.5em"}} onClick={(ev) => this.handleSearchClick(ev)}>Search</OwcButton>
                </td>
              </tr>
              <tr>
                <td> <OwcTypography>Sort by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%" }}
                      value={this.state.sortBy}
                      onChange={(ev) => this.handleSortChange(ev)}>
                    {this.state.sortList.map(item => (
                      <option key={"NavSort" + item}>{item}</option>
                    ))}
                  </select>
                </td>
                <td align="right"> 
                  <OwcButton  style={{marginLeft:"0.5em", width:"auto"}} onClick={() => this.handleResetClick()}>Reset</OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
              position: "absolute",
              top: "-0.8em",
              fontSize: "0.75em",
              backgroundColor: "white",
              border: "1px solid white", zIndex:"1"}}>
            Filter by:
          </div>
          <div style={{display:"flex", alignItems:"flex-start"}}>
            <table style={{minHeight: tableMinHeight}}><tbody>
            <tr>
            {this.renderSelectFilter("Ref System Type:","systemTypeFilter", formattedSystemTypeList)}
            {this.renderSelectFilter("Move Signal:","moveSignalFilter", this.state.moveSignalList)}
            {this.state.moveSignalFilter === "Move Signal"?
              <>
                <OwcTypography key="MoveSignalWarning" id="MoveSignalWarning" style={{fontSize:"1.5em", color:"orange"}}>*</OwcTypography>
                <DelayedTooltip anchor="MoveSignalWarning"
                   key="MoveSignalWarning" id="MoveSignalWarning"
                >
                  Combining Move Signal with other filters (especially Account No) risks Query Timeout
                </DelayedTooltip>
              </>
            :
              "" 
            }
            </tr>
            <tr>
            {this.renderSelectFilter("Country:","countryFilter", fomattedCountryList)}
            {this.renderSelectFilter("Mapping:","mappingFilter", this.state.mappingList)}
            </tr>
            <tr>
            {this.renderSelectFilter("Enriched:","enrichedFilter", this.state.enrichedList)}
            {this.renderSelectFilter("Excluded:","excludedFilter", this.state.excludedList)}
            </tr>
          </tbody></table>
          </div>
        </div>
      </div>
    );
  }

  
  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    //decide based on the space for this control needs to be squeezed
    let fontSize = 0.75;

    let selectedDevice = null;
    if (isRecentMessage(this.state.displayDeviceDetails))
    {
      selectedDevice = extractTimedMessage(this.state.displayDeviceDetails);
    }

    let showLocationForDevice = null;
    if (isRecentMessage(this.state.displayLocationForDevice))
    {
      showLocationForDevice = extractTimedMessage(this.state.displayLocationForDevice);
      console.log(`showing location for device ${showLocationForDevice}`);
    }

    // width of the div below is set to 99% to avoid the "wobbles" that can happen when expanding a table row without a slight expansion room to the side.
    return (
      <div className="DeviceSearch" style={{marginLeft: ".35em", width:"99%"}}>
        <DeviceDetails
          key="DeviceDetailsDisplay"
          deviceId={selectedDevice}
          showNow={this.state.displayDeviceDetails===null?null:this.state.displayDeviceDetails}
          onAgreementClick={(customerAgreementId)=> {this.props.onAgreementClick(customerAgreementId)}}
          onExclusionChange={(deviceId, excluded, comment) => {
            const newSearchedDevices = this.state.items.slice();
            let result = newSearchedDevices.find(item => item.deviceId === deviceId);
            if (result !== undefined) {
              result.excluded = excluded;
              result.deviceComment = comment;
              this.setState({items: newSearchedDevices});
            }
          }}
        />        
        <DeviceCuration
          width="98%"
          key="DeviceCurationDisplay"
          deviceId={showLocationForDevice}
          showNow={this.state.displayLocationForDevice===null?null:this.state.displayLocationForDevice}
          userName={this.props.userName}
          onCuration={() => this.loadSearchResults()}
        /> 
        {this.renderSearchAndSort()}
        <br />
        {this.renderTable()}
        {
          this.state.items.length > 0 && (
            <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                hidePageSize={false} total={this.state.totalPages} pageSizeOptions={[10,20,50]}
                onPageChange={(ev) => this.handlePageChange(ev.detail)}
                style={{display:"flex", marginLeft: "auto"}}>
              <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}>Rows per Page</OwcTypography></div>
              <div ><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
            </OwcPagination>
          )
        }
      </div>
    );
  }
}

export default DeviceSearch;
