import {OwcInput, OwcIconButton, OwcButton, OwcTypography, OwcPagination } from '@one/react';
import React from 'react';
import {configData} from "../../config.js";
import '../../styles.scss';
import {fetchSigned} from "../../shared/Utilities.js"
import AgreementList from "./AgreementList.js"

/**
 * Renders a selection control for agreements
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class AgreementSelection extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {isLoaded:true,
      searchList: ["Customer Name", "Agreement Title", "Agreement ID"],
      page: 1,
      rowsPerPage: 10,
      searchBy: "Customer Name",
      searchResults: [],
    };
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
  }


  loadSearchResults(resetPage=true) {
    const pageNumber = resetPage? 1: this.state.page
    this.setState({
      isLoaded: false,
      page: pageNumber,
      error: null
    });
    //gather searchData
    const searchData = {
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm
    };

    console.log(JSON.stringify(searchData));

    fetchSigned(configData.USECASE_API_URL + "agreement-search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,      
            searchResults: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (errorMsg) => {
          this.setState({
            isLoaded: true,
            error: "Error Loading use cases" + errorMsg
          });
        }
      )
  }

  /**
   * Handles changes to the pagination of the results
   * @param {*} ev The event object that describes the change
   */
  handlePageChange(ev) {
    // TODO Pagination is not working yet, handle it in the page (no API)
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults(false));
    }
  }


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {    
    const fontSize = 0.75;
    let searchItems = [...this.state.searchResults];

    // Filter out the entries that are in the selected list
    if (searchItems !== undefined) {
     searchItems = searchItems.filter(item => undefined === this.props.selectedList.find(
                                              selectedItem => {return selectedItem.customerAgreementId === item.customerAgreementId})
                                            );
    }
    const totalPages = Math.ceil(searchItems.length / this.state.rowsPerPage);
    const startIndex = this.state.rowsPerPage * (this.state.page-1)
    const endIndex = Math.min(startIndex + this.state.rowsPerPage, searchItems.length)

    searchItems = searchItems.slice(startIndex,endIndex);
    return (
      <>
      <OwcTypography variant="title6">Search Agreements</OwcTypography>
    <div style={{display:"flex"}}>
      <select style={{position: "relative", zIndex:"2", height:"3em" }}
          value={this.state.searchBy}
          onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }} >
        {this.state.searchList.map(item => (<option key={"AgreementSelectionNavSearch" + item}>{item}</option>))}
      </select>
      <OwcInput
            style={{marginLeft:"0.5em"}}
            placeholder="Press Enter to Search..."
            round={false}
            variant="filled"
            no-clear-icon
            value={this.state.searchTerm}
            onValueChange={(ev) => this.setState({ searchTerm: ev.detail })}
            onKeyUp={(ev) =>{this.setState({ searchTerm: ev.target.value });
              if (ev.key === "Enter") {
                // Cancel the default action, if needed
                ev.preventDefault();
                // Start the search
                this.loadSearchResults();
              }}}>
          <OwcIconButton slot="prefix" icon="search" onClick={() => this.loadSearchResults()} />
        </OwcInput>
        <OwcButton style={{marginLeft:"0.5em", height:"3em" }} elevated
            onClick={() => this.loadSearchResults()} >
          Search
        </OwcButton>
      </div>
    <div style={{display:"flex", justifyContent: "flex-start", overflowX:"auto"}}>

      <div style={{flexBasis:"30%"}}>
        <AgreementList agreementList={searchItems}
          onAction={(agreement)=>{this.props.onAddSelectedItem(agreement)}}
          keyPrefix="SearchAgreementList"
          title="Search Results"
          actionIcon="arrow_rightwards"
          actionTooltip= "Select this agreement"
        />
         {
          searchItems.length > 0 &&
          (
            <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                hidePageSize={false} total={totalPages} pageSizeOptions={[10,20,50]}
                onPageChange={(ev) => this.handlePageChange(ev.detail)}
                style={{display:"flex", marginLeft: "auto"}}>
              <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}>Rows per Page</OwcTypography></div>
              <div ><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {totalPages} </OwcTypography></div>
            </OwcPagination>
          )
        }
      </div>
      <div style={{flexBasis:"10%"}}/>
      <AgreementList style={{flexBasis:"30%"}}
        agreementList={this.props.selectedList}
        onAction={(agreement)=>{this.props.onRemoveSelectedItem(agreement)}}
        keyPrefix="SelectedAgreementList"
        title="Selected Agreements"
      />
    </div>
    </>
    );
  }

}

AgreementSelection.defaultProps = {
  selectedList: [],
  onRemoveSelectedItem: ()=>{},
  onAddSelectedItem: ()=>{},
}

export default AgreementSelection;
