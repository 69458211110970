import { OwcTypography } from '@one/react';
import React from 'react';
import '../../styles.scss';
import { formatDate, convertToDate } from "../../shared/Utilities.js"
import SimpleDateInput from './SimpleDateInput.js';

/**
 * Renders a set of coverage dates.
 *
 * @copyright Roche 2024
 * @author Geoffroy Maillol
 */
class CoverageDateInput extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
      super(props);
      this.state = {
        coverageStartDate: this.props.item.validityStartDate,
        coverageStartWarning: this.checkCoverageStartWarning(),
        coverageEndDate: this.props.item.validityEndDate,
        coverageEndWarning: this.checkCoverageEndWarning(),
      };
  }

  getCurrentStartDate() {
    const date = this.state?.coverageStartDate ? this.state.coverageStartDate : this.props.item.validityStartDate;
    return convertToDate(date);
  }

  getCurrentEndDate() {
    const date = this.state?.coverageEndDate ? this.state.coverageEndDate : this.props.item.validityEndDate;
    return convertToDate(date);
  }

  getMinDate() {
    let retVal = -8640000000000000; // default value
    if (this.props.isSupportedByLocalAgreement === false) {
      retVal = this.getCurrentStartDate();
      if (this.props.agreement.validFrom !== null) {
        if (convertToDate(this.props.agreement.validFrom) > retVal) {
          retVal = this.props.agreement.validFrom;
        }
      }
    }
    return convertToDate(retVal);
  }

  getMaxDate() {
    let retVal = 8640000000000000; // default value
    if (this.props.isSupportedByLocalAgreement === false) {
      retVal = this.getCurrentEndDate();
      if (this.props.agreement.terminationDate !== null) {
        if (convertToDate(this.props.agreement.terminationDate) < retVal) {
          retVal = this.props.agreement.terminationDate;
        }
      }
    }
    return convertToDate(retVal);
  }

  isDateWithinLimits(date) {
    return convertToDate(date) <= this.getMaxDate() && date >= this.getMinDate();
  }

  checkCoverageStartWarning() {
    return this.props.isSupportedByLocalAgreement === false && (
      (
        (
          !this.isDateWithinLimits(this.getCurrentStartDate())
        ) || this.props.item.validityStartDateSuggested === true
      )
    );
  }

  checkCoverageEndWarning() {
    return this.props.isSupportedByLocalAgreement === false && (
      (
        !this.isDateWithinLimits(this.getCurrentEndDate())
      ) || this.props.item.validityEndDateSuggested === true
    );
  }

  handleValueChange(newDate, fieldName) {
    const newStartDate = fieldName === "validityStartDate" ? newDate : this.props.item.validityStartDate;
    const newEndDate = fieldName === "validityEndDate" ? newDate : this.props.item.validityEndDate;
    this.setState({
      coverageStartDate: newStartDate,
      coverageEndDate: newEndDate,
    }, () => {
      // Update validity following the value update.
      this.setState({
        coverageStartWarning: this.checkCoverageStartWarning(),
        coverageEndWarning: this.checkCoverageEndWarning(),
      });
    });
    this.props.onValueChange({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    return (
      <div style={{display:"flex", flexDirection:"row", alignItems:"baseline", gap:"1em", marginBottom:"0.5em"}} >
        <OwcTypography>Coverage Start</OwcTypography>
        <SimpleDateInput name="coverageStart" label="Coverage Start"
            value={this.state.coverageStartDate}
            showExampleDate={false}
            disabled={this.props.isSupportedByLocalAgreement}
            onValueChange={(ev) => this.handleValueChange(ev, "validityStartDate")}
            assistiveText={
              this.props.item.validityStartDateSuggested === true
              ? `Suggested start date, not saved yet`
              : `Agreement valid from ${
                this.props.agreement.validFrom === null
                  ? "undefined date" 
                  : formatDate(this.props.agreement.validFrom)}`
            }
            displayWarning={this.state.coverageStartWarning} />
        <OwcTypography>Coverage End</OwcTypography>
        <SimpleDateInput name="coverageEnd" label="Coverage End"
            value={this.state.coverageEndDate}
            showExampleDate={false}
            disabled={this.props.isSupportedByLocalAgreement}
            onValueChange={(ev) => this.handleValueChange(ev, "validityEndDate")}
            assistiveText={
              this.props.item.validityEndDateSuggested === true
              ? `Suggested end date, not saved yet`
              : `Agreement Termination Date ${
                this.props.agreement.terminationDate === null
                ? "is not defined"
                : this.props.agreement.entitlementDateValidityVaries === true
                  ? "varies by entitilement"
                  : formatDate(this.props.agreement.terminationDate)
                }`
            }
            displayWarning={this.state.coverageEndWarning} />
      </div>
    )
  }
}

export default CoverageDateInput
