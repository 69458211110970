import {OwcList, OwcTypography} from '@one/react';
import React from 'react';
import '../../styles.scss';
import AgreementListItem from "./AgreementListItem.js";

/**
 * Renders a list control for agreements
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class AgreementList extends React.Component {
  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
  }



  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    return (
      <div style={{display:"flex", flexDirection:"column"}}>
      <OwcList size={this.props.size}>
        {this.props.hideTitle===true?
            <></>
          :
            <div slot="title">{this.props.title}</div>
        }
        {this.props.agreementList.map(agreement => (
          <AgreementListItem
            agreement={agreement}
            showActionIcon={this.props.showActionIcon}
            actionIcon={this.props.actionIcon}
            actionTooltip={this.props.actionTooltip}
            keyPrefix={this.props.keyPrefix}
            onAction={this.props.onAction}
          />
        ))}
      </OwcList>
      {this.props.agreementList.length === 0?
        <OwcTypography style={{marginLeft:"1em"}}>No Agreements</OwcTypography>
      :
        ""
      }
      </div>
    );
  }

}

AgreementList.defaultProps = {
  agreementList: [],
  hideTitle: false,
  title: "Selected Agreements",
  size: "medium",
  showActionIcon: true,
  actionIcon: "circle_clear",
  actionTooltip: "Remove this agreement from the selection",
  keyPrefix: "AgreementListItem",
  onAction: ()=>{},
}

export default AgreementList;
