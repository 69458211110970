import React from 'react';
import PortalReactDOM from 'react-dom'
import './styles.scss';
import { configData } from "./config.js";
import CaptureAgreement from './agreements/CaptureAgreement';
import TranslateAndStructure from './agreements/TranslateAndStructure';
import Harmonize from './agreements/Harmonize';
import ReviewAndPublish from './agreements/ReviewAndPublish';
import AgreementNavigation from './agreements/AgreementNavigation';
import MapAccounts from './agreements/MapAccounts.js';
import MapDevices from './agreements/MapDevices.js';
import VerifyDeviceMapping from './agreements/VerifyDeviceMapping';
import UseCaseNavigation from './useCase/UseCaseNavigation.js';
import RegisterUseCase from './useCase/RegisterUseCase.js';
import ClassifyUseCase from './useCase/ClassifyUseCase.js';
import RecordDecisionUseCase from './useCase/RecordDecisionUseCase.js';
import MappingUseCase from './useCase/MappingUseCase.js';
import PublishUseCase from './useCase/PublishUseCase.js';
import ReferenceLists from './steward/ReferenceLists';
import SystemTypes from './steward/SystemTypes';
import UserView from './steward/UserView';
import NavigationSidebar from './components/general/NavigationSidebar.js';
import FirstTimeWelcome from './components//user/FirstTimeWelcome.js';
import DelayedTooltip from "./components/general/DelayedTooltip.js";
import DeviceSearch from './components/device/DeviceSearch';
import PreferenceList from './components/user/PreferenceList';
import DataProduct from './dataProduct/DataProduct.js';
import {
  cleanUserName, b64Encode, b64Decode, fetchSigned, formatDate, createTimedMessage
} from './shared/Utilities';

import {
  OwcHeader, OwcHeaderRow, OwcIcon, OwcIconButton,
  OwcTypography, OwcModalDialog, OwcButton, OwcMenu,
  OwcListItem, OwcBadge, OwcPopover, OwcProgressSpinner,
  OwcLogo
} from '@one/react';

import loginBackground from "./images/ContractLaw-wikipedia-public-domain.jpg"
import loginBackgroundChristmas from "./images/ContractLaw-christmas.jpg"

import { Amplify, Auth } from "aws-amplify";


/**
 * The main entry point for the applicaiton
 *
 * @copyright Roche 2022
 * @author Nick Draper
 */

// The following line is required to get the application
// work with webpack 5.
window.Buffer = window.Buffer || require("buffer").Buffer;

Amplify.configure(configData.COGNITO_CONFIG)
class App extends React.Component {
  USER_KNOWN = "User with known preferences";
  USER_FIRST_TIME = "First time user without preferences";
  USER_DECLINED_PREFERENCES = "First time user without preferences, but declined to set any";
  CAN_ACCESS_DATA_ENTITLEMENTS = "data-entitlements";
  CAN_ACCESS_DATA_PRODUCTS_ONLY = "data-products-only";

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: configData.TAB_AGREEMENTS,
      splitterSize: 35,
      customerAgreementId: null,
      useCaseId: null,
      updateAgreementList: null,
      updateUseCaseList: null,
      searchAccountNo: {},
      unsavedChanges: false,
      pendingTabChange: null,
      pendingAgreementChange: null,
      pendingUseCaseChange: null,
      userIsAuthenticated: false,
      userIsAuthorized: false,
      userName: null,
      userPreferences: {},
      userFirstVisit: this.USER_KNOWN,
      groups: null,
      alternateGroups: null,
      waiting: true,
      notificationMenuVisible: false,
      showUserMenu:false,
      notificationCounts: {},
      notificationChangeCounts: {},
      accountFilterSettings: "",
      deviceFilterSettings: "",
      systemTypeFilterSettings: "",
      systemTypeTabSelected: "",
      deviceMoveFilterSettings: "",
      databaseStateMessage: "Database is available",
      databaseStateStyle: { backgroundColor: "var(--one-color-green-400)"},
      databaseStateShow: false,
      dataProductWindow: null,
    }
  }

  /**
   * Runs when the page is accessed, and again when it is called back from the authentication process.
   * Extracts the application roles from the AWS authentication token
   */
  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        const idToken = (await Auth.currentSession()).getIdToken();
        const username = cleanUserName(idToken.payload["cognito:username"]);
        const roles = idToken.payload["roche:roles"];
        const userAccess =  this.getUserAccess(roles);
        this.setState({
            userName: username,
            selectedTab: this.getInitialTab(userAccess),
            userIsAuthenticated: true,
            userIsAuthorized: userAccess? true: false,
            waiting: false,
            groups: b64Encode(roles)
          }, 
          () => {
            this.loadNotificationCounts();
            this.loadUserPreferences();
          });

        if (configData.DEV_OR_PROD.includes("development")) {
          // This calls an API that will not be available on production,
          // so should only be called for development environments.
          this.getDatabaseStateAndStartIfRequired();
        }
      }
    } catch (e) {
      this.setState({
        userName: null,
        groups: null,
        waiting: false,
      });
      if (e !== 'No current user') {
        alert(e);
      }
    };
  }

  getDomainRoleName(roleName) {
    return roleName + configData.ENV_ROLE_SUFFIX;
  }

  getUserAccess(roles) {
    const canAccessDataEntitlements = (
      roles.includes(this.getDomainRoleName(configData.ROLE_STEWARD)) ||
      roles.includes(this.getDomainRoleName(configData.ROLE_AFFILIATE)) ||
      roles.includes(this.getDomainRoleName(configData.ROLE_VIEWER))
    );
    const canAccessDataProducts = (
      roles.includes(this.getDomainRoleName(configData.ROLE_CONNECTED_DEVICES)) ||
      roles.includes(this.getDomainRoleName(configData.ROLE_CONSUMER))
    );

    if (canAccessDataEntitlements) {
      return this.CAN_ACCESS_DATA_ENTITLEMENTS;
    } else if (canAccessDataProducts && !canAccessDataEntitlements) {
      return this.CAN_ACCESS_DATA_PRODUCTS_ONLY;
    }
    return null;
  }

  getInitialTab(userType) {
    if (userType === this.CAN_ACCESS_DATA_PRODUCTS_ONLY) {
      return configData.TAB_DATA_PRODUCTS;
    }
    return configData.TAB_AGREEMENTS;
  }

  TAB_MAP = {
    [configData.TAB_AGREEMENTS]: {
      authorisedRoles: [configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_EXTRACT]: {
      authorisedRoles: [configData.ROLE_STEWARD, configData.ROLE_AFFILIATE],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_TRANSLATE]: {
      authorisedRoles: [configData.ROLE_STEWARD, configData.ROLE_AFFILIATE],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_HARMONIZE]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_OVERVIEW]: {
      authorisedRoles: [configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_MAP_ACCOUNTS]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_MAP_DEVICES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_VERIFY_DEVICE_MAPPING]: {
      authorisedRoles: [configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER],
      subSection: "AGREEMENT_MANAGEMENT"
    },
    [configData.TAB_USE_CASES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "USE_CASE_MANAGEMENT"
    },
    [configData.TAB_REGISTER_USE_CASES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "USE_CASE_MANAGEMENT"
    },
    [configData.TAB_CLASSIFY_USE_CASES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "USE_CASE_MANAGEMENT"
    },
    [configData.TAB_MAPPING_USE_CASES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "USE_CASE_MANAGEMENT"
    },
    [configData.TAB_PUBLISH_USE_CASES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "USE_CASE_MANAGEMENT"
    },
    [configData.TAB_REFERENCE_LISTS]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "STEWARD_MENU"
    },
    [configData.TAB_SYSTEM_TYPES]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "STEWARD_MENU"
    },
    [configData.TAB_DEVICE_SEARCH]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "STEWARD_MENU"
    },
    [configData.TAB_USER_VIEW]: {
      authorisedRoles: [configData.ROLE_STEWARD],
      subSection: "STEWARD_MENU"
    },
    [configData.TAB_DATA_PRODUCTS]: {
      authorisedRoles: [
        configData.ROLE_STEWARD,
        configData.ROLE_CONSUMER,
        configData.ROLE_CONNECTED_DEVICES,
      ],
      subSection: "DATA_PRODUCTS"
    },
  }

  getVisibleTabsForRole(role) {
    return Object.keys(this.TAB_MAP).filter(
      key => this.TAB_MAP[key].authorisedRoles.includes(role)
    );
  }

  getTabsForSection(section) {
    return Object.keys(this.TAB_MAP).filter(
      key => this.TAB_MAP[key].subSection.includes(section)
    );
  }

  /**
   * Identifies if the tab should be hidden for the current role
   * @param {*} role The role to evaluate against.
   * @returns true if the tab should be hidden
   */
  isCurrentTabHiddenForRole(role) {
    return !this.getVisibleTabsForRole(role).includes(this.state.selectedTab);
  }

  /**
   * Gets the default page for a role
   * @param {*} role The role to get the default page for
   * @returns The name of the default page
   */
  getDefaultPage(role) {
    if (![configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER].includes(role)) {
      return configData.TAB_DATA_PRODUCTS;
    }

    if (this.getTabsForSection("AGREEMENT_MANAGEMENT").includes(this.state.selectedTab)) {
      if (role === configData.ROLE_VIEWER) {
        return configData.TAB_OVERVIEW;
      }
      return configData.TAB_EXTRACT;
    }

    return configData.TAB_AGREEMENTS;
  }

  /**
   * Handles a change in the users role from the role selection combo box
   * @param {*} newRole The new role selected
   */
  handleRoleChange(newRole) {
    const updatedState = {};
    if (this.state.alternateGroups == null) {
      // store the original version of the groups.
      updatedState.alternateGroups = this.state.groups;
    }
    updatedState.groups = b64Encode(this.getDomainRoleName(newRole));

    if ((this.state.unsavedChanges === false) 
        && (this.isCurrentTabHiddenForRole(newRole))) {
      updatedState.selectedTab = this.getDefaultPage(newRole);
    }

    this.setState(updatedState);
  }

  /**
   * Checks if a user has a particular set of roles, this takes into account the _dev or _prod suffixes
   * @param {*} rolesToCheck A list of roles to check against the users roles
   * @param {*} allowAlternate If true then the alternate groups location is checked
   * @returns true / false
   */
  userHasRoles(rolesToCheck, allowAlternate = false) {
    let groupsToCheck = this.state.groups? b64Decode(this.state.groups): ""; 
    if (allowAlternate) {
      groupsToCheck += this.state.alternateGroups? b64Decode(this.state.alternateGroups): "";
    }
    const roleFound = [];
    rolesToCheck.forEach(role => {
      roleFound.push(groupsToCheck.includes(this.getDomainRoleName(role)));
    });
    return roleFound.some(value => value === true);
  }

  /**
   * Loads the counts for the notifications from the database
   */
  loadNotificationCounts() {
    if (this.userHasRoles([configData.ROLE_STEWARD])) 
    {
      fetchSigned(configData.NOTIFICATIONS_API_URL)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("Notifications Response");
          console.log(result);
          const counts = {};
          const countsChanges = {};
          counts[configData.TAB_MAP_ACCOUNTS] = result["AccountMappingNeedsAttention"];
          countsChanges[configData.TAB_MAP_ACCOUNTS] = result["AccountMappingChanges"];
          counts[configData.TAB_MAP_DEVICES] = result["DeviceMappingNeedsAttention"];
          countsChanges[configData.TAB_MAP_DEVICES] = result["DeviceMappingChanges"];
          counts[configData.TAB_SYSTEM_TYPES_CONNECTED_DEVICES] = result["ConnectedDeviceMappingNeedsAttention"];
          counts[configData.TAB_SYSTEM_TYPES_INSTALLED_BASE] = result["InstalledBaseMappingNeedsAttention"];
          counts[configData.TAB_DEVICE_SEARCH] = result["DeviceMoveSignals"];
          this.setState({notificationCounts:counts, notificationChangeCounts:countsChanges});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );
    }
  }

  getDatabaseStateAndStartIfRequired() {
    fetchSigned(configData.DATABASE_CHECK_API_URL)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(`Database check response: ${JSON.stringify(result)}`);
          const databaseState = result?.database_instance_state?.DBInstances?.[0]?.DBInstanceStatus || "unknown";
          let style = { backgroundColor: "var(--one-color-yellow-400)"};
          let message = `Database is in state ${databaseState}`;
          let showDatabaseState = true;
          let startDatabase = false;

          if (databaseState === "available") {
            style = { backgroundColor: "var(--one-color-green-400)"};
            showDatabaseState = false;
          } else if (databaseState === "stopped") {
            style = { backgroundColor: "var(--one-color-red-400)"};
            message += ", it should start shortly, please try to reload the application.";
            startDatabase = true;
          } else if (databaseState === "starting") {
            message += ", please allow 5-10 minutes for it to start.";
          }

          this.setState({
            databaseStateMessage: message,
            databaseStateStyle: style,
            databaseStateShow: showDatabaseState,
          });
          return startDatabase;
        },
        (error) => {
          console.log(`Error when checking database status: ${error}`);
        }
      ).then(
        (startDatabase) => {
          if (startDatabase) {
            console.log("Attempting to start database.");
            fetchSigned(configData.DATABASE_START_API_URL)
              .then(res => res.json())
              .then(
                (result) => {
                  console.log(`Started database: ${result}`);
                },
                (error) => {
                  console.log(`Error when starting database: ${error}`);
                }
              );
          } else {
            console.log("No need to start database.");
          }
        },
        (error) => {
          console.log(`Error when starting database: ${error}`);
        }
      );
  }

  /**
   *  Loads the user preferences for the current user from the database
   */
  loadUserPreferences() {
    if (this.state.userName !== null) 
    {
      fetchSigned(configData.USER_API_URL + `preferences/${this.state.userName}/`)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("User Preferences Response");
          console.log(result);
          if (result.length === 0) {
            if (this.state.userFirstVisit===this.USER_FIRST_TIME) {
              // we have already shown the first time display in this session, don't show it again.
              this.setState({userPreferences:{},
                userFirstVisit:this.USER_DECLINED_PREFERENCES});
            } else {
              this.setState({userPreferences:{},
                userFirstVisit:this.USER_FIRST_TIME});
            }
          } else {
            this.setState({userPreferences:result[0],
                           userFirstVisit:this.USER_KNOWN});
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      );
    }
  }


  /**
   * handles the row click event from the agreementNavigation control
   * @param {*} ev the event
   * @param {*} agreementId  the selected agreeemntId
   */
  handleAgreementSelection(ev, agreementId) {
      this.setState(
        {
          customerAgreementId: agreementId,
          selectedTab: this.userHasRoles([configData.ROLE_AFFILIATE, configData.ROLE_STEWARD])
            ? configData.TAB_EXTRACT
            : configData.TAB_OVERVIEW
        }
      );
  }

    /**
   * handles the row click event from the useCaseNavigation control
   * @param {*} ev the event
   * @param {*} useCaseId  the selected useCaseId
   */
  handleUseCaseSelection(ev, useCaseId) {
    this.setState(
      {
        useCaseId: useCaseId,
        selectedTab: configData.TAB_REGISTER_USE_CASES
      }
    );
  }

  /**
   * handles the add new click event from the agreementNavigation control
   * @param {*} ev the event
   */
  handleAddAgreementClick(ev) {
    if (this.state.unsavedChanges === true) {
      if (this.state.pendingAgreementChange === null) {
        this.setState({ pendingAgreementChange: -1 });
      }
    } else {
      const newState = { customerAgreementId: null }
      if (this.state.selectedTab !== configData.TAB_EXTRACT) {
        newState.selectedTab = configData.TAB_EXTRACT;
      }
      this.setState(newState);
    }
  }

  /**
   * handles the add new click event from the useCaseNavigation control
   * @param {*} ev the event
   */
  handleAddUseCaseClick(ev) {
    if (this.state.unsavedChanges === true) {
      if (this.state.pendingUseCaseChange === null) {
        this.setState({ pendingUseCaseChange: -1 });
      }
    } else {
      const newState = { useCaseId: null }
      if (this.state.selectedTab !== configData.TAB_REGISTER_USE_CASES) {
        newState.selectedTab = configData.TAB_REGISTER_USE_CASES;
      }
      this.setState(newState);
    }
  }

  /**
   * Handles a request to change the selected tab (page)
   * If there are unsaved changes a confirmation dialogue overlay will appear.
   * @param {*} tabName 
   */
  handleTabChange(tabName) {
    if (this.state.unsavedChanges === true) {
      if (this.state.pendingTabChange === null) {
        this.setState({ pendingTabChange: tabName });
      }
    } else {
      const newState = { selectedTab: tabName }
      if (tabName === configData.TAB_AGREEMENTS 
          || tabName === configData.TAB_USE_CASES 
          || tabName === configData.TAB_DATA_PRODUCTS) {
        newState.selectedAgreementId = null;
      }
      this.setState(newState);
    }
  }

  handleMainTabChange(tabName) {
    this.setState(
      {
        searchAccountNo: {},
        accountFilterSettings: "All",
        deviceFilterSettings: "All",
        deviceMoveFilterSettings: "All",
      },
      () => {
        this.handleTabChange(tabName);
        this.loadNotificationCounts();
      });
  }

  handleAgreementsClick() {
    this.handleMainTabChange(configData.TAB_AGREEMENTS);
  }

  handleUseCasesClick() {
    this.handleMainTabChange(configData.TAB_USE_CASES);
  }

  handleDataProductClick() {
    this.handleMainTabChange(configData.TAB_DATA_PRODUCTS);
  }

  /**
   * Handles when the agreement data has changed and the Agreement Navigation display needs to be updated, and notification counts updated
   * @param {*} agreementId 
   */
  handleListChanged(agreementId) {
    this.setState({
      updateAgreementList: Date.now(),
      customerAgreementId: agreementId
    }, () => this.loadNotificationCounts())
  }

  
  /**
   * Handles when the use case data has changed and the use case Navigation display needs to be updated
   * @param {*} agreementId 
   */
  handleUseCaseListChanged(useCaseId) {
    this.setState({
      updateUseCaseList: Date.now(),
      useCaseId: useCaseId
    })
  }

  /**
   * Handles when the agreement device mapping is clicked
   * Directing to the map devices screen for the agreement
   * @param {*} customerAgreementId The agreement to display the mapped devices for
   */
  handleAgreementDeviceMappingClick(customerAgreementId){
    console.log(`handleAgreementDeviceMappingClick in app.js ${customerAgreementId}`);
    console.log(`unsaved changes ${this.state.unsavedChanges}`);
    if (this.state.unsavedChanges===true)
    {
      this.setState({
        pendingAgreementChange: customerAgreementId,
        pendingTabChange: configData.TAB_MAP_DEVICES,
      });
    } else {
      this.setState({
        customerAgreementId: customerAgreementId,
        selectedTab: configData.TAB_MAP_DEVICES,
      });

    }
  }

  /**
   * Handles when an account lookup is clicked
   * Directs to the agreement navigation with that account searched
   * @param {*} accountNo 
   */
  handleAccountSearchClick(accountNo) {
    // set as a dict with the current time to ensure update if clicked again
    const accountNoDict = {};
    accountNoDict[accountNo] = Date.now();
    this.setState({searchAccountNo: accountNoDict}, () => this.handleTabChange(configData.TAB_AGREEMENTS));
  }

  /**
   * Displays a confirmation dialog if the user attempts to leave a page withunsaved changes
   */
  handleModalAbandonChanges() {
    if (this.state.pendingTabChange !== null) {
      const newTab = this.state.pendingTabChange;
      const newState = {
        unsavedChanges: false,
        pendingTabChange: null,
        pendingAgreementChange: null,
        pendingUseCaseChange: null,
        selectedTab: newTab
      };
      if (newTab === configData.TAB_AGREEMENTS) {
        newState.selectedAgreementId = null;
      }
      this.setState(newState);
    } else {
      const newAgreementId = this.state.pendingAgreementChange === -1 ? null : this.state.pendingAgreementChange;
      const newUseCaseId = this.state.pendingUseCaseChange === -1 ? null : this.state.pendingUseCaseChange;
      this.setState({
        unsavedChanges: false,
        pendingTabChange: null,
        pendingAgreementChange: null,
        customerAgreementId: newAgreementId,
        useCaseId: newUseCaseId,
      });
    }
  }

  /**
   * Handles clicks on specfic notifications, directing to the required page
   * @param {*} notificationLabel The notification label that was clicked
   */
  handleNotificationClick(notificationLabel) {
    if (notificationLabel === configData.TAB_MAP_ACCOUNTS)
    {
      this.setState({
        accountFilterSettings: createTimedMessage("Needs Attention"),
        notificationMenuVisible: false
      },
      () => this.handleTabChange(configData.TAB_AGREEMENTS));
    } else if (notificationLabel === configData.TAB_MAP_DEVICES) {
      this.setState({
        deviceFilterSettings: createTimedMessage("Needs Attention"),
        notificationMenuVisible: false
      },
      () => this.handleTabChange(configData.TAB_AGREEMENTS));
    } else if (notificationLabel.startsWith(configData.TAB_SYSTEM_TYPES)) {
      this.setState({
        systemTypeFilterSettings: createTimedMessage("Not mapped"),
        systemTypeTabSelected: createTimedMessage(notificationLabel),
        notificationMenuVisible: false
      },
      () => this.handleTabChange(configData.TAB_SYSTEM_TYPES));
    } else if (notificationLabel.startsWith(configData.TAB_DEVICE_SEARCH)) {
      this.setState({
        deviceMoveFilterSettings: createTimedMessage("Move Signal"),
        notificationMenuVisible: false
      },
      () => this.handleTabChange(configData.TAB_DEVICE_SEARCH));
    }
  }

  /**
   * Resets all filters that might be set by clicking on notifications
   * @param {*} callback The subsequent method to call (no arguments)
   */
  resetNotificationFilters(callback){
    this.setState({ accountFilterSettings: "",
                    deviceFilterSettings: "",
                    systemTypeFilterSettings: "",
                    deviceMoveFilterSettings: "",
                  }, () => callback());
  }

  handleUnsavedState(isUnsaved) {
    if (this.state.unsavedChanges !== isUnsaved) {
      // Only update the state if there's an actual update.
      this.setState({ unsavedChanges: isUnsaved });
    }
  }

  getLoginBackground(){
    const now = new Date();
    const monthNow = now.getMonth() + 1; //months from 1-12
    const dayNow = now.getDate();
    if ((monthNow === 12) && (dayNow > 23)) {
      return loginBackgroundChristmas;
    }
    return loginBackground;
  }

  /**
   * Renders the Log In screen
   * @returns The JSX of the components
   */
  renderLoginScreen() {
    return (
      <div name="loginScreen" style={{ 
        backgroundRepeat:"no-repeat",backgroundImage: `url(${this.getLoginBackground()})`,
        backgroundSize:"100% 100%",
        height:"100%"}}>
        <div>
            {this.state.waiting?
              <OwcProgressSpinner style={{marginTop:"30vh", marginBottom:"40vh"}} />
            :
              <div style={{backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display:"flex", flexDirection:"column",
                  alignItems:"center",
                  height:"calc(100vh - 7em)"}}>
                <OwcTypography variant="title4" style={{paddingTop:"30vh"}}>Please Sign in</OwcTypography>
                <OwcButton style={{marginTop:"1em", marginBottom:"38vh", padding:"0.5em 1em"}}
                  onclick={() => Auth.federatedSignIn()}>
                    <OwcTypography variant="title6">Sign In</OwcTypography>
                </OwcButton>
                <span style={{background: "radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)",
                              display:"flex", flexDirection:"column",
                              alignItems:"center", width:"90%", maxWidth:"80em", position: "fixed",
                              bottom:"0%", border:"1px solid dimgrey", borderRadius:"10px",
                              marginBottom:"0.5em"}}>
                  <p style={{margin:"0.8em", fontSize:"0.4em"}}>
                  <OwcTypography variant="caption" style={{color:"var(--one-color-active-primary-background)", fontWeight:"bold"}}>Confidentiality Disclaimer: </OwcTypography>
                  <OwcTypography variant="caption" style={{color:"dimgrey"}}>I acknowledge that I am bound by confidentiality obligations imposed through my employment 
                    or contractual agreement with Roche in connection with my access to confidential information, including this system and its contents.
                    By entering this system, I confirm that I understand that my activities within this system may be 
                    monitored consistent with local law, and all contents and passwords are confidential information, and that unauthorized disclosure 
                    or use of such confidential information may result in disciplinary action including termination of my employment or services 
                    and/or legal action based on local law.
                  </OwcTypography>
                  </p>
                </span>
              </div>       
            }
          </div>
      </div>
    );
  }

  /**
   * Renders the Not Authorised screen for users who have a valid login but no role to use the application.
   * @returns The JSX of the components
   */
  renderNotAuthorizedScreen() {
    return (
      <div name="notAuthorizedScreen" style={{ 
        backgroundRepeat:"no-repeat",backgroundImage: `url(${this.getLoginBackground()})`,
        backgroundSize:"100% 100%",
        height:"100%" }}>
        <div style={{backgroundColor: "rgba(255, 255, 255, 0.7)",
          display:"flex", flexDirection:"column",
          alignItems:"center"}}>
              <div>
                <OwcTypography variant="title4" style={{paddingTop:"30vh"}}>You are not authorized to use this application (please sign out)</OwcTypography>
                <OwcButton style={{marginTop:"1em", marginBottom:"38vh", padding:"0.5em 1em"}}
                  onClick={() => {
                    console.log("Signing out");
                    this.setState({ userIsAuthenticated: false, waiting: true });
                    Auth.signOut();
                    
                  }}
                >
                <OwcTypography variant="title6">Sign Out</OwcTypography></OwcButton>
              </div> 
          </div>
      </div>
    );
  }

  /**
   * Renders the Main body of the application window
   * @returns The JSX of the controls
   */
  renderBody() {
    if (this.state.selectedTab === configData.TAB_AGREEMENTS) {
      return (
        <AgreementNavigation updateFlag={this.state.updateAgreementList}
          onEditClick={(ev, agreementId) => this.handleAgreementSelection(ev, agreementId)}
          currentAgreement={this.state.customerAgreementId}
          selectedTab={this.state.selectedTab}
          accountFilterSettings={this.state.accountFilterSettings}
          deviceFilterSettings={this.state.deviceFilterSettings}
          userPreferences={this.state.userPreferences}
          searchAccountNo={this.state.searchAccountNo}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])}
          handleAddAgreementClick={(ev) => this.handleAddAgreementClick(ev)}
          canAddNewAgreement={(this.userHasRoles([configData.ROLE_STEWARD, configData.ROLE_AFFILIATE]) === true) }
          isReadOnly={(this.userHasRoles([configData.ROLE_VIEWER]) === true) &&
                      (this.userHasRoles([configData.ROLE_AFFILIATE, configData.ROLE_STEWARD]) === false) }
        />
      );
    } else if (this.state.selectedTab === configData.TAB_USE_CASES) {
      return (
        <UseCaseNavigation updateFlag={this.state.updateUseCaseList}
          onEditClick={(ev, useCaseId) => this.handleUseCaseSelection(ev, useCaseId)}
          handleAddUseCaseClick={(ev) => this.handleAddUseCaseClick(ev)}
          currentAgreement={this.state.useCaseId}
          selectedTab={this.state.selectedTab}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])}/>
      );
    } else if (this.state.selectedTab === configData.TAB_DATA_PRODUCTS) {
      return <DataProduct groups={this.state.groups}/>;
    } else {
        return (this.renderTab(this.state.selectedTab, this.state.customerAgreementId));
    }
  }

  /**
   * Renders the specific selected tab (page)
   * @param {*} selectedTab The name of the selected tab
   * @param {*} customerAgreementId The selected customerAgreementId
   * @returns The JSX of the controls
   */
  renderTab(selectedTab, customerAgreementId) {
    if (selectedTab === configData.TAB_EXTRACT) {
      return (
        <CaptureAgreement customerAgreementId={customerAgreementId}
          onAgreementListChanged={(id) => this.handleListChanged(id)}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />        
      );
    }
    if (selectedTab === configData.TAB_TRANSLATE) {
      return (
        <TranslateAndStructure customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)} />
      );
    }
    if (selectedTab === configData.TAB_HARMONIZE) {
      return (
        <Harmonize customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
        />
      );
    }
    if (selectedTab === configData.TAB_REFERENCE_LISTS) {
      return (
        <ReferenceLists
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
        />
      );
    }
    if (selectedTab === configData.TAB_MAP_ACCOUNTS) {
      return (
        <MapAccounts customerAgreementId={customerAgreementId}
          onAgreementListChanged={(id) => this.handleListChanged(id)}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          onAccountSearchClick={(accountNo) => this.handleAccountSearchClick(accountNo)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />
      );
    }
    if (selectedTab === configData.TAB_MAP_DEVICES) {
      return (
        <MapDevices customerAgreementId={customerAgreementId}
          onAgreementListChanged={(id) => this.handleListChanged(id)}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          onAccountSearchClick={(accountNo) => this.handleAccountSearchClick(accountNo)}
          onAgreementClick={(customerAgreementId) => this.handleAgreementDeviceMappingClick(customerAgreementId)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />
      );
    }
    if (selectedTab === configData.TAB_VERIFY_DEVICE_MAPPING) {
      return (
        <VerifyDeviceMapping customerAgreementId={customerAgreementId}
          onAgreementListChanged={(id) => this.handleListChanged(id)}
          onAccountSearchClick={(accountNo) => this.handleAccountSearchClick(accountNo)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />
      );
    }
    if (selectedTab === configData.TAB_OVERVIEW 
        || selectedTab === configData.TAB_OVERVIEW_FOR_STEWARD) {
      return (
        <ReviewAndPublish customerAgreementId={customerAgreementId}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} 
          onAccountSearchClick={(accountNo) => this.handleAccountSearchClick(accountNo)} />
      );
    }
    if (selectedTab === configData.TAB_SYSTEM_TYPES) {
      return (
        <SystemTypes 
          userName={this.state.userName}
          onUpdateNotifications={() => {this.loadNotificationCounts()}}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)} 
          systemTypeFilterSettings={this.state.systemTypeFilterSettings}
          systemTypeTabSelected={this.state.systemTypeTabSelected}
        />
      );
    }
    if (this.state.selectedTab === configData.TAB_DEVICE_SEARCH) {
      return (
        <DeviceSearch updateFlag={this.state.updateAgreementList}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])}
          userName={this.state.userName}
          deviceMoveFilterSettings={this.state.deviceMoveFilterSettings}
          onAgreementClick={(customerAgreementId) => this.handleAgreementDeviceMappingClick(customerAgreementId)}
        />
      );
    }
    if (this.state.selectedTab === configData.TAB_USER_VIEW) {
      return (
        <UserView 
          userPreferences={this.state.userPreferences}
          userName={this.state.userName}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)} 
        />
      );
    }
    if (selectedTab === configData.TAB_REGISTER_USE_CASES) {
      return (
        <RegisterUseCase useCaseId={this.state.useCaseId}
          onUseCaseListChanged={(id) => this.handleUseCaseListChanged(id)}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />        
      );
    }
    if (selectedTab === configData.TAB_CLASSIFY_USE_CASES) {
      return (
        <ClassifyUseCase useCaseId={this.state.useCaseId}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />        
      );
    }
    if (selectedTab === configData.TAB_RECORD_DECISIONS_USE_CASES) {
      return (
        <RecordDecisionUseCase useCaseId={this.state.useCaseId}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />        
      );
    }
    if (selectedTab === configData.TAB_MAPPING_USE_CASES) {
      return (
        <MappingUseCase useCaseId={this.state.useCaseId}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />
      );
    }
    if (selectedTab === configData.TAB_PUBLISH_USE_CASES) {
      return (
        <PublishUseCase useCaseId={this.state.useCaseId}
          onUnsavedChangesChange={(isUnsaved) => this.handleUnsavedState(isUnsaved)}
          userName={this.state.userName}
          dataSteward={this.userHasRoles([configData.ROLE_STEWARD])} />
      );
    }
  }

  /**
   * Renders a warning dialog if the user attemts to leave a page with undaved changes
   * @returns The JSX of the controls
   */
  renderWarningDialog() {
    return (
      <OwcModalDialog disableBackdropClick disableEscapeKeydown disableScrollLock="false" hideBackdrop="false" size="small"
        visible={(
          this.state.unsavedChanges &&
          (
            (this.state.pendingTabChange !== null) ||
            (this.state.pendingAgreementChange !== null) ||
            (this.state.pendingUseCaseChange !== null)
          )
        )}>
        <div style={{ justifyContent: 'flex-end', padding: '10px' }}>
          You have unsaved changes, are you sure you want to move without saving?
        </div>
        <div slot="header" style={{ justifyContent: 'flex-end', padding: '10px' }}>
          Unsaved Changes
        </div>
        <div slot="footer" style={{ justifyContent: 'flex-end', padding: '5px' }}>
          <table width="100%">
            <tbody><tr>
              <td align="left">
                <OwcButton style={{ width: "fit-content" }} onclick={() => this.setState({ pendingTabChange: null, pendingAgreementChange: null, pendingUseCaseChange: null })}                    >
                  No, stay on this page
                </OwcButton>
              </td>
              <td align="right">
                <OwcButton style={{ width: "fit-content" }} onclick={() => this.handleModalAbandonChanges()}>
                  Yes, abandon unsaved changes
                </OwcButton>
              </td>
            </tr></tbody>
          </table>
        </div>
      </OwcModalDialog>
    );
  }

  /**
   * Renders the Version and environment labels
   * @returns The JSX of the controls
   */
  renderVersion() {
    return (
      <div style={{ display: "flex", marginLeft: "auto",flexDirection:"row", gap:"1em", alignItems:"center"}}>
          {this.renderEnvironment()}
          <div style={{ display: "flex", flexDirection:"row" }} >
            <div style={{ display: "flex", marginTop:"0.3em" }} >
              <OwcTypography  variant="body" style={{color:"white"}}>Build:</OwcTypography>
            </div>
            <div style={{ display: "flex", flexDirection:"column" }}>
              <a href={configData.VERSION_BUILD_URL}><OwcTypography  variant="body" style={{color:"white"}}>{configData.VERSION_BUILD_NUMBER}</OwcTypography></a>
              <OwcTypography  variant="body" style={{color:"white"}}>{formatDate(configData.VERSION_DATE)}</OwcTypography>
            </div>
            {
              this.state.databaseStateShow
              ?
                <div style={{ display: "flex", marginTop:"0.5em", marginLeft:"0.5em" }}>
                  <OwcIconButton
                      key="databaseCheckIcon"
                      id="databaseCheckIcon"
                      elevated size="xSmall">
                    <OwcIcon name="data" style={this.state.databaseStateStyle} />
                  </OwcIconButton>
                  <DelayedTooltip key="databaseCheckIconTooltip" 
                      anchor="databaseCheckIcon"
                      placement="right">
                    {this.state.databaseStateMessage}
                  </DelayedTooltip>
                </div>
              :""
            }
          </div>
      </div>
    );
  }

  /**
   * Renders the environment label for all environments other than production
   * @returns The JSX of the controls
   */
  renderEnvironment() {
    const productionBranch = "production";
    if (!(configData.BRANCH_NAME === productionBranch ||
          configData.BRANCH_NAME.endsWith(" " + productionBranch)))
    {
      let backColour = "LightBlue";
      if (configData.DEV_OR_PROD === "production")
      {
        backColour = "Orchid";
      }
      if (configData.DEV_OR_PROD === "development")
      {
        backColour = "LightGreen";
      }
      if (configData.DEV_OR_PROD === "release-candidate")
      {
        backColour = "HotPink";
      }
      return (
        <div style={{ display: "flex", marginLeft: "auto",flexDirection:"row", gap:"1em", backgroundColor:backColour, borderRadius:"15px"}}>
            <OwcTypography variant="title5" style={{ paddingLeft:"0.5em"}}>Environment:</OwcTypography>
            <OwcTypography variant="title5">{configData.BRANCH_NAME}</OwcTypography>
            <OwcTypography variant="title5" style={{ paddingRight:"0.5em"}}>- {configData.DEV_OR_PROD}</OwcTypography>
        </div>
      );
    }
  }

  /**
   * Renders the navigation sidebar for the role of the user
   * @returns The JSX of the controls
   */
  renderSidebar() {
    const tabs = [];
    const referenceTabs = [
      configData.TAB_REFERENCE_LISTS, configData.TAB_SYSTEM_TYPES,
      configData.TAB_DEVICE_SEARCH, configData.TAB_USER_VIEW
    ];
    const useCaseTabs = [
      configData.TAB_REGISTER_USE_CASES,
      configData.TAB_CLASSIFY_USE_CASES,
      configData.TAB_RECORD_DECISIONS_USE_CASES,
      configData.TAB_MAPPING_USE_CASES,
      configData.TAB_PUBLISH_USE_CASES,
    ];

    if (referenceTabs.includes(this.state.selectedTab)) {
      tabs.push({text:"Steward Menu", disabled:true});
      tabs.push({text:configData.TAB_REFERENCE_LISTS});
      tabs.push({text:configData.TAB_SYSTEM_TYPES});
      tabs.push({text:configData.TAB_DEVICE_SEARCH});
      tabs.push({text:configData.TAB_USER_VIEW});
    } else if (useCaseTabs.includes(this.state.selectedTab)) {
      tabs.push({text:"Use Case", disabled:true});
      tabs.push({text:configData.TAB_REGISTER_USE_CASES});
      tabs.push({text:configData.TAB_CLASSIFY_USE_CASES});
      tabs.push({text:configData.TAB_RECORD_DECISIONS_USE_CASES});
      tabs.push({text:configData.TAB_MAPPING_USE_CASES});
      tabs.push({text:configData.TAB_PUBLISH_USE_CASES});
    }
    else {
      tabs.push({text:"Agreement Entitlements", disabled:true});
      if (this.userHasRoles([configData.ROLE_AFFILIATE, configData.ROLE_STEWARD])) {
        tabs.push({text:configData.TAB_EXTRACT});
        tabs.push({text:configData.TAB_TRANSLATE});   
      }
      if (this.userHasRoles([configData.ROLE_STEWARD])) {
        tabs.push({text:configData.TAB_HARMONIZE});
      }
      if (this.userHasRoles([configData.ROLE_STEWARD])) {
        tabs.push({text:configData.TAB_OVERVIEW_FOR_STEWARD});
      } else {
        tabs.push({text:configData.TAB_OVERVIEW});
      }
      
      tabs.push({text:"Device Entitlements", disabled:true});
      if (this.userHasRoles([configData.ROLE_STEWARD])) {
        tabs.push({text:configData.TAB_MAP_ACCOUNTS});
        tabs.push({text:configData.TAB_MAP_DEVICES});
      }
      tabs.push({text:configData.TAB_VERIFY_DEVICE_MAPPING});
    }

    return(
      <NavigationSidebar
        selectedTab={this.state.selectedTab}
        onTabChange={(tabName) => this.resetNotificationFilters(()=>this.handleTabChange(tabName))}
        tabs={tabs} />
    );
  }

  /**
   * Renders the structure of the title bar controls on the right hand side
   * @returns The JSX of the controls
   */
  renderSideMenu() {
    return (
      <div style={{ display: 'flex', alignItems: "center" }}>
        <div style={{ display: 'flex' }}>
          {this.state.userIsAuthorized ? this.renderUserMenu():""}
        </div>
        <div style={{ display: 'flex' }}>
          {this.userHasRoles([configData.ROLE_STEWARD]) ? this.renderNotificationCentre():""}
        </div>
        <div style={{ display: 'flex' }}>
          <OwcLogo name="roche_logo" style={{ width: '48px' }}/>
        </div>
      </div>
    )
  }

  /**
   * Displays the role selection drop down list
   * @returns The JSX of the controls
   */
  renderRoleSelector() {
    return (
      <div style={{ display: 'flex', alignItems: "center" }}>
        {this.userHasRoles([configData.ROLE_STEWARD], true) 
          ? (
            <div style={{ display: 'flex', alignItems: "center" }}>
              <div style={{ display: 'flex' }}>
                <OwcTypography style={{ marginRight: "1em" }}>Current Role</OwcTypography>
              </div>
              <div style={{ display: 'flex', padding: '0px 10px 0px 0px' }}>
                <select style={{ width: '100%', position: "relative" }}
                    defaultValue={configData.ROLE_STEWARD}
                    onChange={(event) => this.handleRoleChange(event.target.value)}>
                  <option value={configData.ROLE_STEWARD}>{configData.ROLE_STEWARD}</option>
                  <option value={configData.ROLE_AFFILIATE}>{configData.ROLE_AFFILIATE}</option>
                  <option value={configData.ROLE_VIEWER}>{configData.ROLE_VIEWER}</option>
                  <option value={configData.ROLE_CONSUMER}>{configData.ROLE_CONSUMER}</option>
                  <option value={configData.ROLE_CONNECTED_DEVICES}>{configData.ROLE_CONNECTED_DEVICES}</option>
                </select>
              </div>
            </div>
          )
          : ""
        }
      </div>
    )
  }

  /**
   * Renders the popover menu for the user menu
   * @returns The JSX of the controls
   */
  renderUserMenu() {
    if (this.state.userIsAuthenticated === true) {
      const userName = cleanUserName(this.state.userName);
      return (
        <div>
          <OwcIconButton id='userIconAnchor' family="outlined" style={{ marginLeft:'8px' }} icon="user"
            onClick={() => (this.setState({showUserMenu:false}))} />
          {
            PortalReactDOM.createPortal(
              <OwcPopover  style={{ width: 'auto' }} anchor='userIconAnchor' position='bottom-end'
                  visible={this.state.showUserMenu}
                  onClickOutside={()=>this.setState({showUserMenu:false})} >
                <div style={{ display: 'flex', flexDirection:'column', marginLeft:"0.5em", marginRight:"0.5em", marginBottom: "0.5em"}}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '16px' }}>
                  <span style={{ 
                      background: 'var(--one-color-interaction-default-brand-base)', 
                      color: 'var(--one-color-interaction-default-base)', 
                      fontSize: '24px', padding: '16px', borderRadius: '50%' }}>
                    {userName}
                  </span>
                  {this.userHasRoles([configData.ROLE_STEWARD], true) 
                    ? (
                      <p style={{ 
                          color: 'var(--one-color-interaction-default-intensity-medium)',
                          marginBottom: '0px' }}>
                        {configData.ROLE_STEWARD}
                      </p>
                    )
                    : ""}
                </div>
                <div style={{ display: 'flex', flexDirection:"column", alignItems: "center", gap: "0.5em" }}>
                  {this.renderRoleSelector()}
                  <OwcButton 
                      style={{whiteSpace:"nowrap", flexShrink: "0"}} flat
                      onClick={() => {
                        Auth.signOut();
                        this.setState({ userIsAuthenticated: false, waiting: true });
                      }}>
                    <OwcTypography>Sign Out</OwcTypography>
                  </OwcButton>
                </div>
                {
                  this.userHasRoles([configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER]) 
                  ? (
                    <div>
                      <hr style={{width:"100%"}}/>
                      <OwcTypography style={{fontWeight:"700"}}>Preferences</OwcTypography>
                      <PreferenceList 
                        userName={this.state.userName} 
                        refreshUserPreferences={this.state.showUserMenu}
                        onPreferencesChanged={()=>this.loadUserPreferences()}
                      />
                    </div>
                  )
                  : ""
                }
                
              </div>
              </OwcPopover>,
              document.body)
          }
        </div>
      );
    } else {
      return;
    }
  }

  /**
   * Renders the notification center
   * @returns The JSX of the controls
   */
  renderNotificationCentre() {
    const notificationCounts = this.state.notificationCounts;
    const changeCounts = this.state.notificationChangeCounts;
    const notificationCountsValues = Object.values(notificationCounts);

    if ((notificationCounts !== undefined) && 
        (Object.keys(notificationCounts).length !== 0)) {
      if (notificationCountsValues.length > 0) {
        const totalCounts = notificationCountsValues.reduce((a, b) => a + b); 
        let badgeType = "info";
        if (totalCounts>0) {
          badgeType = "warning";
          if (Object.keys(changeCounts).length !== 0) {
            let totalWarnings = 0;
            for (const [tabName, count] of Object.entries(notificationCounts)) {
              if (tabName in changeCounts) {
                totalWarnings += changeCounts[tabName];
              } else {
                totalWarnings += count;
              }
            }
            
            if (totalWarnings===0) {
              badgeType = "tag";
            }
          }
        }
        return (
          <div key="notification-div" style={{ display: 'flex', alignItems: "center" }}>
            <OwcIconButton key="notification-btn" id="menu-anchor-1"
                onClick={(ev) => {this.setState({notificationMenuVisible: !this.state.notificationMenuVisible})}}>
              <OwcIcon key="notification-icon" name="notification_warning" ></OwcIcon>
            </OwcIconButton>
            <OwcBadge key={"notification-badge" + Math.random()} status={badgeType} 
                style={{position:"relative", top: "-8px", left:"-17px", whiteSpace:"nowrap"}}
                size="medium"
                onClick={(ev) => {this.setState({notificationMenuVisible: !this.state.notificationMenuVisible})}}>
              {totalCounts}
            </OwcBadge>
          {PortalReactDOM.createPortal(
            <OwcMenu anchor="menu-anchor-1" limit="6" alignment="left" visible={this.state.notificationMenuVisible}
                onClickOutside={(e) => {this.setState({notificationMenuVisible: false})}}>
              {this.renderNotificationItem(configData.TAB_MAP_ACCOUNTS, "Account Mappings Need Attention")}
              {this.renderNotificationItem(configData.TAB_MAP_DEVICES, "Device Mappings Need Attention")}
              {this.renderNotificationItem(configData.TAB_SYSTEM_TYPES_CONNECTED_DEVICES, "Connected Device Types Need Mapping")}
              {this.renderNotificationItem(configData.TAB_SYSTEM_TYPES_INSTALLED_BASE, "Installed Base Types Need Mapping")}
              {this.renderNotificationItem(configData.TAB_DEVICE_SEARCH, "Devices with Move Signal")}
            </OwcMenu>,
            document.body
          )}
          </div>
        );
      } 
    }
  }

  /**
   * Renders an individual notification item
   * @param {*} tabName The name of the page related to this control
   * @param {*} name The Name of this notification
   * @returns The JSX of the controls
   */
  renderNotificationItem(tabName, name) {
    const notificationCounts = this.state.notificationCounts;
    const changeCounts = this.state.notificationChangeCounts;
    let icon="confirm";
    let badgeType = "info";
    if (notificationCounts[tabName] > 0) {
      badgeType = "warning";
      icon="alarm";
      if ((tabName in changeCounts) && (changeCounts[tabName])===0) {
        badgeType = "tag";
        icon="message";
      }
    }
    return (
      <OwcListItem icon={icon} style={{ display:"flex"}} no-border
          key={"notification-" + tabName}
          onClick={(e) => {window.scrollTo(0, 0); this.handleNotificationClick(tabName)}}>
        {name}
        <div slot="prefix">
          <OwcIcon name="confirm" />
        </div>
        <div slot="suffix">
          <OwcBadge key={"notification-account-badge" + Math.random()}
              status={badgeType} 
              style={{wordBreak:"keep-all"}}>
            {notificationCounts[tabName]}
          </OwcBadge>
        </div>
      </OwcListItem>
    );
  }

  /**
   * Renders the page
   * @returns The JSX of the controls
   */
  render() {
    return (
      <div name="app">
        {(this.state.userIsAuthorized
          && (this.state.userFirstVisit===this.USER_FIRST_TIME)
          && (this.userHasRoles([configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER])))
          ?<FirstTimeWelcome userName={this.state.userName} 
            onPreferencesChanged={()=>{this.loadUserPreferences()}}
            onVisibleChange={(ev) => {
              if (ev.detail === false) {
                this.loadUserPreferences();
              }}}/>
          : ""
        }
        <OwcHeader>
          <OwcHeaderRow variant="top-primary" elevated>
            <div style={{ display: 'flex' }}>
              <OwcTypography variant="title5" style={{color:"white"}}>Data Entitlements</OwcTypography>
            </div>
            {this.renderVersion()}
          </OwcHeaderRow>
          <OwcHeaderRow variant="primary" elevated>
            <div style={{ display: 'flex' }}>
              {
              (this.state.userIsAuthenticated===true && this.state.userIsAuthorized ===true)? 
                <div style={{ display: 'flex' }}>
                  {
                    this.userHasRoles([configData.ROLE_STEWARD, configData.ROLE_AFFILIATE, configData.ROLE_VIEWER]) 
                    ? <div>
                        <OwcButton style={{marginLeft:"-0.8em", whiteSpace:"nowrap"}} flat
                            onClick={(ev) => this.handleAgreementsClick()}>
                          <OwcTypography variant="body">{configData.TAB_AGREEMENTS}</OwcTypography>
                        </OwcButton>
                      </div>
                    : ""
                  }
                  {
                    this.userHasRoles([configData.ROLE_STEWARD]) 
                    ? <>
                        <OwcButton style={{marginLeft:"1em", whiteSpace:"nowrap"}} flat
                            onClick={(ev) => this.handleUseCasesClick()}>
                          <OwcTypography variant="body">Use Cases</OwcTypography>
                        </OwcButton>
                        <OwcButton style={{marginLeft:"1em", whiteSpace:"nowrap"}} flat
                            onClick={(ev) => this.handleTabChange(configData.TAB_REFERENCE_LISTS)}>
                          <OwcTypography variant="body">Steward Menu</OwcTypography>
                        </OwcButton>
                      </>
                    : ""
                  }
                  {
                    (this.userHasRoles([
                      configData.ROLE_STEWARD,
                      configData.ROLE_CONSUMER,
                      configData.ROLE_CONNECTED_DEVICES
                    ]))
                    ? <OwcButton style={{marginLeft:"1em", whiteSpace:"nowrap"}} flat
                          onClick={(ev) => this.handleDataProductClick()} >
                        <OwcTypography variant="body">{configData.TAB_DATA_PRODUCTS}</OwcTypography>
                      </OwcButton>
                    : ""
                  }
                </div>
              : <div style={{ display: 'flex' }}/>
              }
            </div>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              {this.renderSideMenu()}
            </div>         
          </OwcHeaderRow>
        </OwcHeader>
        {this.state.showAbout ? this.renderAbout() : ""}
        {this.renderWarningDialog()}
        {(this.state.userIsAuthenticated===true && this.state.userIsAuthorized ===true)
         ?( 
            <div name="tabContents" className="tabContents" style={{display:"flex", height:"100%"}}>
              {([configData.TAB_AGREEMENTS, configData.TAB_USE_CASES, configData.TAB_DATA_PRODUCTS ].includes(this.state.selectedTab))
                ? ""
                : <div>
                    {this.renderSidebar()}
                  </div>
              }
              <div style={{flexGrow:"3"}}>
                {this.renderBody()}
              </div>
            </div>
          )
          :(this.state.userIsAuthenticated===true && this.state.userIsAuthorized !==true)
            ? this.renderNotAuthorizedScreen() 
            : this.renderLoginScreen()
        }
      </div>
    );
  }
}

export default App;
