import { OwcInput, OwcIcon, OwcAssistiveText, OwcButton, OwcTypography } from '@one/react';
import React from 'react';
import '../../styles.scss';
import {formatDate, parseDate } from "../../shared/Utilities.js"
import DelayedTooltip from "./DelayedTooltip.js";
import { configData } from "../../config.js";

/**
 * Renders a simple date validation component.
 *
 * @copyright Roche 2024
 * @author Geoffroy Maillol
 */
class SimpleDateInput extends React.Component {
    /**
     * Constructor 
     * 
     * @param props The properties passed
     */
    constructor(props) {
        super(props);
        this.state = {
          currentDate: formatDate(this.props.value),
          validity: { state: "valid" },
        };
    }
    
    setDefaultDate() {
      this.setState({
        currentDate: formatDate(Date.now()),
      });
    }

    getValidityState(dateValue, dateIsEmpty) {
      return !dateIsEmpty && !dateValue
        ? "error"
        : this.shouldDisplayWarning(dateValue)
          ? "warning"
          : "valid";
    }

    updateValidity() {
      this.setState({
        validity: { state: this.getValidityState(parseDate(this.props.value), !this.props.value) },
      });
    }

    componentDidMount() {
      this.updateValidity();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.displayWarning !== this.props.displayWarning) {
        this.updateValidity();
      }
    }

    valueEndsWithFourDigits(value) {
      return /\d{4}$/.test(value);
    }

    valueStartswithFourDigitsAndEndsWithTwoDigits(value) {
      return /^\d{4}.*\d{2}$/.test(value);
    }

    shouldDisplayWarning(currentDate) {
      const isAfterMaxDate = this.props.maxDate && currentDate && currentDate > this.props.maxDate;
      const isBeforeMinDate = this.props.minDate && currentDate && currentDate < this.props.minDate;
      return this.props.displayWarning === true || isAfterMaxDate === true || isBeforeMinDate === true;
    }

    handleDateChange(value) {
      const newState = {
        currentDate: value,
        assistiveText: this.props.assistiveText,
        validity: { state: "valid" }
      };
      let dateValue = null;
      // This is to ensure incomplete dates are not validated too early.
      if (this.valueEndsWithFourDigits(value) ||
          this.valueStartswithFourDigitsAndEndsWithTwoDigits(value)) {
        dateValue = parseDate(value);
        if (dateValue !== null) {
          newState.currentDate = formatDate(dateValue);
        } 
      }

      newState.validity.state = this.getValidityState(dateValue, !value);
      
      this.setState(newState);
      if (dateValue !== null || !value) {
        this.props.onValueChange(value);
      }
    }

    /**
     * Renders the controls
     * @returns The JSX of the controls
     */
    render() {    
      const exampleDate = new Date();
      if (this.props.exampleDate === "max") {
        exampleDate.setFullYear(exampleDate.getFullYear() + 10);
      } else {
        exampleDate.setFullYear(exampleDate.getFullYear() - 10);
      }

      return (
      <div style={{display:"flex", flexDirection:"row"}}>
        <OwcInput label={this.props.label}
            value={this.state.currentDate}
            placeholder={formatDate(exampleDate)}
            onValueChange={(ev) => this.handleDateChange(ev.detail)}
            disabled={this.props.disabled}
            validity={this.state.validity}>
          <OwcIcon slot="prefix" name="schedule_on" family="filled" />
          <span slot="assistive-text">
            {this.props.showExampleDate? `e.g. ${formatDate(exampleDate)}` : ""}
            <OwcAssistiveText wrap>
              {this.props.assistiveText}
            </OwcAssistiveText>
          </span>
        </OwcInput>
        {configData.DEV_OR_PROD.includes("development")
          ? <div>
              <OwcButton id={this.props.name + "defaultDateButton"} onclick={() => this.setDefaultDate()}>
                <OwcTypography variant="title6">{"<"}</OwcTypography>
              </OwcButton>
              <DelayedTooltip key={this.props.name + "defaultDateButtonTooltip"} anchor={this.props.name + "defaultDateButton"}>
                Use current date
              </DelayedTooltip>
            </div>
          : ""
        }
      </div>
      )
    }
}

SimpleDateInput.defaultProps = {
  showExampleDate: true,
  exampleDate: "min"
}

export default SimpleDateInput
