import {OwcListItem, OwcIconButton, OwcIcon, OwcTypography} from '@one/react';
import React from 'react';

import {formatDate} from "../../shared/Utilities.js"
import '../../styles.scss';
import DelayedTooltip from "../general/DelayedTooltip.js";

/**
 * Renders a list item control for agreements
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class AgreementListItem extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {isLoading:true};
  }

  /**
   * Runs once after construction after everything is initialised
   */
  componentDidMount() {
  }

  truncate( str, n, useWordBoundary ){
    if (str.length <= n) { return str; }
    const subString = str.slice(0, n-1); // the original check
    return (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "…";
  };


  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    const agreement = this.props.agreement;
    let warningText = "";
    if (agreement.publishedVersionNo === null) {
      warningText = "This agremeent is not published";
    }
    if (agreement.deviceVerifiedDate === null) {
      if (warningText !== "") {
        warningText += ", "
      }
      warningText += "Device mappings are not verified";
    }
    const itemId = this.props.keyPrefix + agreement.customerAgreementId;
    const truncatedCustomerName = this.truncate(agreement.customerName, 50, false);
    const truncatedAgreementTitle = this.truncate(agreement.agreementTitle, 50, false);

    return (
      <>
      <OwcListItem value={agreement.customerAgreementId}
        key = {itemId}
      >
        <OwcTypography
          id={"title" + itemId}
          style={warningText===""?{}:{color:"orange"}}>{truncatedAgreementTitle}</OwcTypography>
        <span slot="secondary"
          id={"subtitle" + itemId} style={warningText===""?{}:{color:"orange"}}>
            {truncatedCustomerName}
        </span> 
        <div style={{ display: "flex"}}  slot="prefix">
          {agreement.publishedVersionNo !== null
          ?
            <OwcIcon 
              id={"iconPublishedStatus" + itemId} 
              name="certificate" variant="success"
              style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
          :<div key={"iconPublishedStatusSpacer" + itemId} style={{height:"0.95em", width:"1.5em"}} />
          }
          {agreement.deviceVerifiedDate !== null
          ?
            <OwcIcon
              id={"iconVerifiedStatus" + itemId} 
              name="cobas_liat" variant="success"
              style={{fontSize:"1.5em", color: "var(--one-color-green-500)"}} />
          :<div key={"iconVerifiedStatusSpacer" + itemId} style={{height:"0.95em", width:"1.5em"}} />
          }
        </div>
        {this.props.showActionIcon === true?
            <OwcIconButton id={"AgreementRemovBtn" + itemId}
              slot="suffix"
              icon={this.props.actionIcon} 
              onclick={() => this.props.onAction(agreement)}>
            </OwcIconButton>
          :
            <></>
        }
      </OwcListItem>     
        {truncatedAgreementTitle===agreement.agreementTitle?
            ""
          :
            <DelayedTooltip key={"titleTooltip" + itemId} anchor={"title" + itemId} placement="top">{agreement.agreementTitle}</DelayedTooltip> 
        }
        {truncatedCustomerName===agreement.customerName?
            ""
          :
            <DelayedTooltip key={"titleTooltip2" + itemId} anchor={"subtitle" + itemId} placement="top">{agreement.customerName}</DelayedTooltip> 
        }
        {warningText===""?
            ""
          :
            <>
              <DelayedTooltip key={"warningtitleTooltip" + itemId} anchor={"title" + itemId}>{warningText}</DelayedTooltip> 
              <DelayedTooltip key={"warningtitleTooltip2" + itemId} anchor={"subtitle" + itemId}>{warningText}</DelayedTooltip> 
            </>
        }
      {agreement.publishedVersionNo !== null
      ?
      <DelayedTooltip key={"iconPublishedStatusTooltip" + itemId} anchor={"iconPublishedStatus" + itemId}>
        Agreement entilements version {agreement.publishedVersionNo} has been published</DelayedTooltip> 
      :
        ""
      }        
      {agreement.deviceVerifiedDate !== null
      ?
        <DelayedTooltip key={"iconVerifiedStatusTooltip" + itemId} anchor={"iconVerifiedStatus" + itemId}>
        Device mappings have been verified on {formatDate(agreement.deviceVerifiedDate)}</DelayedTooltip>
      :
        ""
      }

      {this.props.showActionIcon === true
      ?
        <DelayedTooltip
            anchor={"AgreementRemovBtn" + itemId}
            placement="bottom">
          {this.props.actionTooltip}
        </DelayedTooltip>
      :
        ""
      }
      </>
    );
  }

}

AgreementListItem.defaultProps = {
  agreement: {customerAgreementId:null,agreementTitle:"",customerName:"",publishedVersionNo:null,deviceVerifiedDate:null},
  showActionIcon: true,
  actionIcon: "circle_clear",
  actionTooltip: "Remove this agreement from the selection",
  keyPrefix: "AgreementListItem",
  onAction: ()=>{},
}

export default AgreementListItem;
