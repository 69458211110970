import { OwcModalDialog, OwcButton, OwcTypography, OwcInput, OwcIcon, OwcComponentHeader } from '@one/react';
import DevicesAtLocation from './DevicesAtLocation.js';
import React from 'react';
import '../../styles.scss';
import {configData} from "../../config.js";
import {fetchSigned, isRecentMessage} from "../../shared/Utilities.js"

/**
 * Renders the device curation and location investigation screen
 *
 * @copyright Roche 2023
 * @author Nick Draper
 */
class DeviceCuration extends React.Component {
  NO_LOCATION_OPTIONS = "No location options available";
  SYSTEM_TYPES_MATCHING = "Matching";
  SYSTEM_TYPES_ANY_MAPPED = "Any Mapped";
  SYSTEM_TYPES_ALL = "All (including modules)";
  LOCATION_LABEL_DEVICE = "Device Location";
  LOCATION_LABEL_RDS = "Remote Service Device";
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      deviceId: props.deviceId,
      deviceLocationLoaded: false,
      deviceLocation: {},
      originalComment: null,
      submissionState: "",
      visible: false,
      locatedBy: this.NO_LOCATION_OPTIONS,
      locationList: [this.NO_LOCATION_OPTIONS],
      showSystemTypes: this.SYSTEM_TYPES_MATCHING,
      showSystemTypesList: [this.SYSTEM_TYPES_MATCHING, this.SYSTEM_TYPES_ANY_MAPPED, this.SYSTEM_TYPES_ALL],
      locationAccountNo: null,
      newSerialNo: null,
      messagesLoaded: true,
      curationMessages: [],
    };
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.deviceId !== this.props.deviceId) {
      if (this.props.deviceId !== null) {
        this.setState({deviceId: this.props.deviceId,
            showSystemTypes: this.SYSTEM_TYPES_MATCHING,
            submissionState: "",
            newSerialNo: null,
            originalComment: null,
            deviceLocation: {},
            curationMessages: [],
            messagesLoaded: true,
            locationAccountNo: null,
            locationAccountName: null,
            locationAddress: null,
          },
          () => this.loadDeviceLocation(this.props.deviceId));
      }

    }
    if (this.props.showNow !== null && prevProps.showNow !== this.props.showNow && isRecentMessage(this.props.showNow)) {
      this.setState({visible: true});
    }
    
  }


  /**
   * Loads the location data to display for a device
   * @param {*} deviceId the device_id to load
   */
  loadDeviceLocation(deviceId) {
    console.log("loading device location for ", deviceId);
    this.setState({ deviceLocationLoaded: false});

    fetchSigned(configData.DEVICE_DETAILS_API_URL + "location/" + deviceId + "/")
    .then(res => res.json())
    .then(
      (result) => {
          if (result[0].comment === null) {
            result[0].comment = "";
          }
          
          let locationList = [];
          if (result[0].instrumentLocationAccountNo !== null) {
            locationList.push(`${this.LOCATION_LABEL_DEVICE} (${result[0].instrumentLocationAccountNo})`);
          }
          if (result[0].rsdLocationAccountNo !== null) {
            locationList.push(`${this.LOCATION_LABEL_RDS} (${result[0].rsdLocationAccountNo})`);
          }

          let locationAccountNo = null;
          let locationAccountName = null;
          let locationAddress = null;
          if (locationList.length === 0) {
            locationList = [this.NO_LOCATION_OPTIONS];
          } else {
            if (result[0].instrumentLocationAccountNo !== null) {
              locationAccountNo = result[0].instrumentLocationAccountNo;
              locationAccountName = result[0].locationAccountName;
              locationAddress = result[0].instrumentAddress;
            } else if (result[0].rsdLocationAccountNo !== null) {
              locationAccountNo = result[0].rsdLocationAccountNo;
              locationAccountName = result[0].rsdLocationAccountName;
              locationAddress = result[0].rsdAddress;
            }
          }

          this.setState({ deviceLocation: result[0],  
                          originalDeviceComment:  result[0].comment === null? "":result[0].comment,
                          deviceLocationLoaded: true,
                          locatedBy: locationList[0],
                          locationList: locationList,
                          locationAccountNo: locationAccountNo,
                          locationAccountName: locationAccountName,
                          locationAddress: locationAddress,
                        });

      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Handles the change event for the located by drop down
   * @param {*} ev the event
   */
  handleLocatedByChange(ev) {
    if (ev.target.value !== this.state.locatedBy) {   
      const deviceLocation = this.state.deviceLocation;
      let accountNo = deviceLocation.rsdLocationAccountNo;
      let accountName = deviceLocation.rsdLocationAccountName;
      let accountAddress = deviceLocation.rsdAddress;
      if (ev.target.value.startsWith(this.LOCATION_LABEL_DEVICE)) {
        accountNo = deviceLocation.instrumentLocationAccountNo;
        accountName = deviceLocation.locationAccountName;
        accountAddress = deviceLocation.instrumentAddress;
      }

      if (this.state.locationAccountNo === accountNo) {
        this.setState({locatedBy: ev.target.value});
      } else {
        this.setState({locatedBy: ev.target.value,
          locationAccountNo: accountNo,
          locationAccountName: accountName,
          locationAddress: accountAddress});
      }
    }
  }

  /**
   * Handles clicks on the Check Button
   */
  handleCheckClick() {
    console.log("Checking serial_no ", this.state.newSerialNo);
    this.setState({ curationMessages: [], messagesLoaded: false});

    const submissionData = {
      deviceId: this.props.deviceId,
      originalSerialNo: this.state.deviceLocation.serialNo,
      newSerialNo: this.state.newSerialNo,
    };


    fetchSigned(configData.DEVICE_DETAILS_API_URL + "check-curation/", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submissionData)
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({ curationMessages: result,  
                        messagesLoaded: true});
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  handleSaveClick() {
    console.log(`Saving change to serial_no from ${this.state.deviceLocation.serialNo} to ${this.state.newSerialNo}`);
    this.setState({ submissionState: "Saving ..." });

    const submissionData = {
      deviceId: this.props.deviceId,
      originalSerialNo: this.state.deviceLocation.serialNo,
      newSerialNo: this.state.newSerialNo,
      userName: this.props.userName===undefined?"Unknown":this.props.userName,
      comment: this.state.deviceLocation.comment,
    };


    fetchSigned(configData.DEVICE_DETAILS_API_URL + "curate/", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submissionData)
    })
    .then(res => res.json())
    .then(
      (result) => {
        let submissionState = "Saved";
        let stayVisible = false;
        if (result.status !== undefined) {
          submissionState = result.status;
          stayVisible = true;
        } else {
          this.props.onCuration(this.props.deviceId);
        }
        console.log(`Curation response: ${result.status}`);
        this.setState({
          submissionState: submissionState,
          visible: stayVisible
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  /**
   * Render the section displaying the device identity
   */
  renderIdentitySection() {
    const deviceLocation = this.state.deviceLocation;
    let accountNo = this.state.locationAccountNo;
    let accountName = this.state.locationAccountName;
    let accountAddress = this.state.locationAddress;
    return (
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <table>
          <tbody>
            <tr>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Ref System Type:</OwcTypography></td>
              <td><OwcTypography>{deviceLocation.referenceSystemType + (deviceLocation.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}</OwcTypography></td>
              <td></td>
              <td></td>
              <td width="5em"></td>
              <td style={{marginRight:"1em"}}>
                <OwcTypography style={{ "fontWeight": "bold" }}>Located by:</OwcTypography>
              </td>
              <td>                
                <div style={{display: "flex", flexDirection:"row", alignItems:"center"}} >
                <select
                  value={this.state.locatedBy}
                  onChange={(ev) => this.handleLocatedByChange(ev)}
                >
                  {this.state.locationList.map((item,index) => (
                    <option key={"LocationFilter" + index}>{item}</option>
                  )
                  )}
                </select>
                <OwcIcon name="locator" type="filled" variant="primary"/>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Serial No:</OwcTypography></td>
              <td><OwcTypography>{deviceLocation.serialNo}</OwcTypography></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Account No:</OwcTypography></td>
              <td><OwcTypography>{accountNo}</OwcTypography></td>
            </tr>
            
            <tr>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>New Serial No:</OwcTypography></td>
              <td>
              <OwcInput
                  key={"NewSerialNo"}
                  value={this.state.newSerialNo}
                  label="New Serial No"
                  style={{width:"100%", marginRight: "0.2em"}}
                  onKeyUp={(ev) => {
                    if (ev.target.value !== null) {
                      const newSerialNo =  ev.target.value.trim();
                      const submissionState = (
                          newSerialNo !== deviceLocation.serialNo 
                          && this.state.deviceLocation.comment === this.state.originalDeviceComment
                        ) ? "" : "Unsaved Changes";
                      this.setState({
                        newSerialNo: newSerialNo,
                        submissionState: submissionState,
                        curationMessages: []
                      });
                    }
                   }}
                />
              </td>
              <td>
                <OwcButton style={{ width: "fit-content", marginRight: "0.2em" }}
                  onclick={()=>this.handleCheckClick()} 
                  disabled={(this.state.newSerialNo === null 
                            || this.state.newSerialNo.length === 0
                            || this.state.deviceLocation.length === 0
                            || this.state.newSerialNo === this.state.deviceLocation.serialNo
                            || this.state.curationMessages.length > 0) 
                            ? true : false}
                >
                  Check
                </OwcButton>
              </td>
              <td>
              <OwcButton style={{ width: "fit-content", marginRight: "0.2em" }}
                  onclick={()=>this.handleSaveClick()} 
                  disabled={(this.state.curationMessages.length > 0) ? false : true} >
                {this.state.submissionState === "Saving ..." ||  this.state.submissionState === "Saved" ? this.state.submissionState : "Save Changes"}
              </OwcButton>
              </td>
              <td></td>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Account Name:</OwcTypography></td>
              <td><OwcTypography>{accountName}</OwcTypography></td>
            </tr>
            <tr>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Comment:</OwcTypography></td>
              <td colSpan="3">
                <OwcInput
                  key={"DeviceComments"}
                  value={deviceLocation.comment}
                  label="Comment"
                  style={{width:"100%", marginRight: "0.2em"}}
                  onKeyUp={(ev) => {
                    if (ev.target.value !== null) {
                      const newComment =  ev.target.value.trim();
                      if (newComment !== this.state.deviceLocation.comment) {
                        let newIdentity = {...this.state.deviceLocation};
                        newIdentity.comment = newComment;
                        const submissionState = (
                            newIdentity.excluded === this.state.originalDeviceExcluded 
                            && newIdentity.comment === this.state.originalDeviceComment
                          ) ? "" : "Unsaved Changes";
                        this.setState({
                          deviceLocation: newIdentity,
                          submissionState: submissionState
                        });
                      }
                    }
                  }}
                />
              </td>
              <td></td>
              <td style={{marginRight:"1em"}}><OwcTypography style={{ "fontWeight": "bold" }}>Account Address:</OwcTypography></td>
              <td><OwcTypography>{accountAddress}</OwcTypography></td>
            </tr>
          </tbody>
        </table>
        <div>
          <OwcButton style={{ width: "fit-content", marginRight: "0.2em" }}
            onclick={()=>{this.setState({visible: false})}} 
          >
            Close without changes
          </OwcButton>
        </div>
      </div>
    );
  }
 
  /**
   * Renders the curation messages after clicking check
   */
  renderCurationMessages(){
    const messages = this.state.curationMessages;
    if (messages.length > 0) {
      return(
        <>
          {messages.map((message,index) => this.renderMessage(message, index))}
        </>
      );
    }
  }

  renderMessage(message, index){
    return(
      <div key={"curationMessage" + index}>
        <OwcTypography style={message.priority === "warning"?{color:"orange"}:{}}>{message.text}</OwcTypography>
      </div>
    );
  }

  renderErrorMessage(){
    if (this.state.submissionState && !["Saving ...", "Saved", "Unsaved Changes"].includes(this.state.submissionState)) {
      return (
        <OwcTypography style={{color: "red"}}>{this.state.submissionState}</OwcTypography>
      );
    }
    return "";
  }

  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    const modalStyle = { maxWidth: '100%' };
    return (
      <OwcModalDialog 
          visible={this.state.visible}
          size="large"
          cardProps={{ style: modalStyle }}
          disableBackdropClick={this.props.disableBackdropClick} 
          disableEscapeKeydown={this.props.disableEscapeKeydown} 
          disableScrollLock={this.props.disableScrollLock} 
          denseContent={this.props.denseContent}
          onVisibleChange={(ev)=>{
            if (ev.detail === false) 
              {this.setState({visible: false})};
            }
          }>
        
        <div style={{display:"flex", alignItems: "stretch", flexDirection:"column", padding: "0px 20px 20px 20px"}}>
          {this.renderIdentitySection()}
          {this.renderErrorMessage()}
          {this.renderCurationMessages()}
          <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", gap: "1em", alignItems: "flex-end"}}>
            <div style={{display: "flex", flexDirection:"row", alignItems:"center"}} >
              <OwcTypography variant="title6">Devices at the same location - {this.state.locatedBy}</OwcTypography>
              <OwcIcon name="locator" type="filled" variant="primary"/>
            </div>
            <div style={{display:"flex", flexDirection: "row", gap: "1em", alignItems: "baseline"}}>
              <OwcTypography>Show Ref System Types:</OwcTypography>
              <select
                value={this.state.showSystemTypes}
                onChange={(ev) => {
                  if (ev.target.value !== this.state.showSystemTypes) {       
                    this.setState({showSystemTypes: ev.target.value});
                  }
                }}
              >
                {this.state.showSystemTypesList.map((item,index) => (
                  <option key={"SystemTypeFilter" + index}>{item}</option>
                )
                )}
              </select>
            </div>
          </div>
          <DevicesAtLocation
            deviceId={this.state.deviceId}
            locationNo={this.state.locationAccountNo===null?"":this.state.locationAccountNo}
            systemTypeFilter={this.state.showSystemTypes}
          />
        </div>
        <OwcComponentHeader slot="header">
          <OwcTypography style={{lineHeight:"0.2em"}} variant="title6">Curate / Show devices at the same location</OwcTypography>
        </OwcComponentHeader>

      </OwcModalDialog>
    );

  }
}

DeviceCuration.defaultProps = {
  deviceId: null,
  closeButtonText: "Close",
  closeButtonTooltip: "Close this overlay and return to the screen below",
  visible: true,
  disableBackdropClick: false,
  disableEscapeKeydown: false,
  disableScrollLock: false,
  denseContent: true,
  width: "90%",
  disableNavigation: false,
  onCuration:() => {}
}

export default DeviceCuration
